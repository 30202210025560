
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setSubscription } from "./function";
import { PushNotifications } from '@capacitor/push-notifications';
import { tl_alert } from './@assets/tlBootstrap/tlBootstrap/JS/tl-js';
import { Haptics } from '@capacitor/haptics';
// import { Plugins } from '@capacitor/core';

// const { DeviceMotion } = Plugins;

const playSound = () => {
    // Jouer un son
    const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/alert_song.wav`);
    audio.play();

};
const hapticsVibrate = async () => {
    await Haptics.vibrate();
    // await DeviceMotion.turnScreenOn();
};

const vibrateDevice = () => {
    // Vibrer l'appareil
    navigator.vibrate([200, 100, 200, 100, 200]);
};

const tlshowNotification = (title, body, icon=`${process.env.PUBLIC_URL}/logo192.png`) => {
    // Vérifier si les notifications sont prises en charge par le navigateur
    if (!("Notification" in window)) {
        console.error("Ce navigateur ne prend pas en charge les notifications desktop");
        return;
    }

    // Demander la permission pour afficher des notifications
    Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            // Créer la notification
            const notification = new Notification(title, {
                body: body,
                icon: icon,
                visibility: 1
            });

            // Éventuellement, émettre un son ou vibrer
            playSound();
            vibrateDevice();
        } else {
            tl_alert("","Permission refusée pour afficher des notifications");
        }
    });
};


export const PushNotificationListeners = () => {
    const nav = useNavigate();

    useEffect(() => {
        const addListeners = async () => {
            await PushNotifications.addListener('registration', token => {
                setSubscription("tlapp_" + token.value);
            });

            await PushNotifications.addListener('registrationError', err => {
                // Gérer l'erreur de l'enregistrement
            });

            await PushNotifications.addListener('pushNotificationReceived', async notification => {
                // const { Ntitle, Nbody,click_action, screen } = notification.data;
                hapticsVibrate()
                // tlshowNotification(Ntitle,Nbody)
                if (!notification.additionalData.foreground) {
                    await PushNotifications.addListener('pushNotificationActionPerformed', action => {
                        if (action.notification.data.click_action === 'FLUTTER_NOTIFICATION_CLICK' && action.notification.data.screen) {
                            nav(`${process.env.PUBLIC_URL}/home?scr=${action.notification.data.screen}`);
                            window.location.href = `${process.env.PUBLIC_URL}/home?scr=${action.notification.data.screen}`
                        }
                        // vibrateDevice()
                    });
                }
                else {
                    // vibrateDevice()
                }
            });
        };

        addListeners();

        return () => {
            // Nettoyer les écouteurs si nécessaire
        };
    }, [nav]);

    return null; // Pas de rendu nécessaire pour ce composant
};
