
import { useEffect,useRef,useState } from "react";
import { Col, Row } from "../widgets/layout_orient"
import { Icon, SubmitButton } from "../widgets/widgets";
import "./askAge.css";
// import { TLDatePicker } from "../@assets/tlBootstrap/tlBootstrap/JS/tlDatePicker";
import { formatNowDate,calculate_age } from "../@assets/tlBootstrap/tlBootstrap/JS/calculate_date";
import { Title,Text } from "../widgets/widgets.js";
import { Qs,Qst } from "../@assets/tlBootstrap/tlBootstrap/JS/tl-js.js";
import { myDat,nominateDate } from "../function.js";

export default function AskAge ({onReturn=()=>{},onChange=()=>{},onReady=()=>{}}) {
    // let tlDatePicker
    let [currenAge, setCurrenAge] = useState(0)
    let [currentDate, setCurrentDate] = useState("")
    let [currentState, setCurrentState] = useState("var(--app-secondary-text-color)")
    let [inErr, setInErr] = useState(false)
    const useEffectExecuted = useRef(false); // Utilisation d'une référence
    function loadDate (date=null) {
        if(date === null ) {
            date = new Date()
        }
        let lemxmD = date.getDate()
        let lemxmM = (date.getMonth()+1)
        let xmD = lemxmD.toString().length < 2? "0"+lemxmD: lemxmD
        let xmM = lemxmM.toString().length < 2? "0"+lemxmM: lemxmM
        let xmY = date.getFullYear() > ((new Date()).getFullYear()-18) ? ((new Date()).getFullYear()-18) : date.getFullYear()
        Qst(`[index="3"]`).setAttr("max",(new Date()).getFullYear()-18)
        let constructd = `${xmY}/${xmM}/${xmD}`
        myDat.value = constructd
        // console.log(date)
        Qst(`[index="1"]`).value = xmD
        Qst(`[index="2"]`).value = xmM
        Qst(`[index="3"]`).value = xmY
    
        let nominate = nominateDate(constructd)
        Qst(`[index="1"]`).thisPreviousSibling().innerText = nominate.d
        Qst(`[index="2"]`).thisPreviousSibling().innerText = nominate.m
        let nwD = formatNowDate(new Date())
        let getAdiff = calculate_age(myDat.value.ddmmyyyy(),nwD).y
        setCurrenAge(getAdiff)
        setCurrentDate(myDat.value.ddmmyyyy())
        onChange(myDat.value.ddmmyyyy())
        if (getAdiff >= 18 ) {
            setCurrentState('var(--app-secondary-text-color)')
            setInErr(false)
        } 
        else {
            setCurrentState("var(--app-tertio-color)")
            setInErr(true)
        }
    }
    useEffect (()=>{
        if (!useEffectExecuted.current) {
            
            // let tlDatePicker = new TLDatePicker({
            //     w:100,
            //     unity: "%",
            // })
            setTimeout(async () => {
                loadDate()
                // console.log("compte")
                Qst("*[length]","input",async (e)=>{
                    let ln = parseInt(e.getAttr("length"))
                    let nF = parseInt(e.getAttr("index"))
                    e.value = e.value.slice(0,ln)
                    if ( e.value.length === ln ) {
                        if ( parseInt(Qst(`[index="1"]`).value) > 31) {
                            Qst(`[index="1"]`).value = 31
                        }
                        if ( parseInt(Qst(`[index="2"]`).value) > 12) {
                            Qst(`[index="2"]`).value = 12
                        }
                        if ( parseInt(Qst(`[index="3"]`).value) > parseInt(Qst(`[index="3"]`).getAttr("max"))) {
                            Qst(`[index="3"]`).value = parseInt(Qst(`[index="3"]`).getAttr("max"))
                        }
                        else if ( parseInt(Qst(`[index="3"]`).value) < parseInt(Qst(`[index="3"]`).getAttr("min"))) {
                            Qst(`[index="3"]`).value = parseInt(Qst(`[index="3"]`).getAttr("min"))
                        }
                        if ( nF < 3 ) {
                            Qst(`[index="${nF+1}"]`).focus()
                        }
                        else {
                            e.blur()
                        }
                        let formated = new Date(parseInt(Qst(`[index="3"]`).value),(parseInt(Qst(`[index="2"]`).value)-1),parseInt(Qst(`[index="1"]`).value))
                        if (!Qst(`[index="1"]`).value !== "" && !Qst(`[index="2"]`).value !== "" && !Qst(`[index="3"]`).value !== "") loadDate(formated) 
                        console.log(formated)
                        e.thisParent().styleThis("borderColor","rgb(70, 107, 255)")
                    }
                    
                })
                Qst("*[length]","blur",async (e)=>{
                    let formated = new Date(parseInt(Qst(`[index="3"]`).value),(parseInt(Qst(`[index="2"]`).value)-1),parseInt(Qst(`[index="1"]`).value))
                    if ( Qst(`[index="1"]`).value !== "" && !Qst(`[index="2"]`).value !== "" && !Qst(`[index="3"]`).value !== "")  loadDate(formated) 
                    // e.thisParent().removeAttr("focus-mode")
                })
                // tlDatePicker.init()
                // tlDatePicker.onChange = ()=>{
                //     let nwD = formatNowDate(new Date())
                //     let getAdiff = calculate_age(tlDatePicker.value.ddmmyyyy(),nwD).y
                    
                //     setCurrenAge(getAdiff)
                //     setCurrentDate(tlDatePicker.value.ddmmyyyy())
                //     onChange(tlDatePicker.value.ddmmyyyy())
                //     if (getAdiff >= 18 ) {
                //         setCurrentState('var(--app-secondary-text-color)')
                //         setInErr(false)
                //     } 
                //     else {
                //         setCurrentState("var(--app-tertio-color)")
                //         setInErr(true)
                //     }
                // }
            }, 1000);
            useEffectExecuted.current = true; // Marquer que useEffect a été exécuté
        }
    },[])
    async function checkAge (e) {
        if (currenAge >= 18 ) {
            setCurrentState('var(--app-primary-color)')
            setInErr(false)
            onReady(e,true,currentDate,currenAge)
        } 
        else {
            setInErr(true)
            onReady(e,false,currentDate,currenAge)
            setCurrentState("var(--app-tertio-color)")
        }
    }
    
    return (
        <Col className="centry ageScreen tl_ph-45 tl_gap-30 tl_position-relative">
            <Icon onClick={onReturn}  icon="bx-chevron-left tl_position-absolute tl_top-10 tl_left-5 " className="leadingIco tl_cursor-pointer"/>
            <Title content="Mettez votre date de naissance" className="tl_w-100 askAgeTitle tl_mb-40" calibre={2} style={{color:"white"}}/>
            <Col>
                <div className="row center tl_align-items-center tl_gap-20" error={inErr?"true":"false"}>
                    <div className="date-case" style={{width:"51px"}}>
                        <label htmlFor="d-case">.mer</label>
                        <input type="number" placeholder="dd" length="2" name="day" min="1" max="31" id="d-case" index="1"/>
                    </div>/
                    <div className="date-case" style={{width:"51px"}}>
                        <label htmlFor="m-case">.jun</label>
                        <input type="number" placeholder="mm" length="2" name="day" min="1" max="12" id="m-case" index="2"/>
                    </div>/
                    <div className="date-case" style={{width:"69px"}}>
                        <label htmlFor="y-case">an</label>
                        <input type="number" placeholder="yyyy" length="4" name="day" id="y-case" min="1960" index="3"/>
                    </div>
                </div>
                {/* <tl-datepicker error={inErr?"true":"false"}></tl-datepicker> <Row className="ageErr tl_align-items-center tl_gap-5"> <Icon icon="bx-info-circle"/> <Text content="18 ans au moins"/></Row> */}
                <Row className="ageErr tl_align-items-center tl_gap-5"> <Icon icon="bx-info-circle"/> <Text content="18 ans au moins"/></Row>
            </Col>
            <p style={{color:currentState}} className="age tl_color-white tl_w-100 tl_mt-20">{currenAge+" "+(currenAge > 1? "ans":"an")}</p>
            <SubmitButton type="button" text="Terminé" onClick={checkAge} style={{paddingTop: "10px",paddingBottom: "10px"}}/>
        </Col>
    )

}


