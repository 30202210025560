
import { useEffect, useState } from "react";
import "./widgets.css";
import { copyAdress, Qs, Qst, Toast } from "../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { Col } from "./layout_orient";


export function SearchField ({value="",filterData=[{}],id="sr",hasResult=true,noResultText="",parentClass="",name="",style={},label="label",className='',onFocus=()=>{},onBlur=()=>{},onInput=()=>{},onClick=()=>{},onFiltered=()=>{},searchIcon=false,...rest}) {
    
    // fonctions par rapport aux inputs en générale
    function focusAction (ev) {
        let e = ev.target
        onFocus(e,ev)
    }
    function blurAction (ev) {
        let e = ev.target
        onBlur(e,ev)
    }

    function inputed (e) {
        onInput(e.target,e)
    }
    function search (e) {
        onInput(e.target.previousElementSibling)
    }
    function openFilters (e) {
        let wrapper = e.thisParent().nextElementSibling
        if (!wrapper.hasAttribute("filter-active")) {
            wrapper.setAttribute("filter-active","")
        }
        else {
            wrapper.removeAttribute("filter-active")
        }
    }
    function onFilter (e) {
        let wrapper = e.thisParent()
        if (!wrapper.hasAttribute("filter-active")) {
            wrapper.setAttribute("filter-active","")
        }
        else {
            wrapper.removeAttribute("filter-active")
        }
        onFiltered(e.getData("value"))
    }
    function mouseOuted (e) {
        Qst('body').addEventListener("click",(ev)=>{
            if (!ev.target.containsClass("filterElementsWrapper") && !ev.target.containsClass("filterElement")){
                Qs(".filterElementsWrapper").forEach((ele) => {
                    ele.removeAttribute("filter-active")
                    // tl_alert("","clicked")
                })
                
            }
        },{once:true})
    }
    if (value === "" || value === null){
        return (
            <Col className={parentClass+" searchfielderC"}>
                <div className={className+" searchfielder"} invalid={hasResult?"false":"true"}>
                    <Icon icon="bx-filter" onClick={(e)=>{openFilters(e.target)}} className="searchIcoL filterLead" title="filtre" />
                    <input type={"text"} placeholder={label} onInput={inputed} name={name} id={name} style={style} onFocus={focusAction} onBlur={blurAction} onClick={onClick} className={className+" field"} {...rest}/>
                    <Icon icon="bx-search" className="searchIcoR" onClick={search} />
                </div>
                <div className="filterElementsWrapper" onMouseOut={mouseOuted}>
                    <Text content={"Chercher par:"} className="tl_color-white tl_color-grey tl_fs-12"/>
                    {
                        filterData?.map((dt,index)=>{
                            return (
                                <span key={"flt_"+index} className="filterElement" data-value={dt.value} onClick={(e)=>{onFilter(e.target)}}>{dt.name}</span>
                            )
                        })
                    }
                </div>
                <Notice isActive={hasResult?false:true} className="tl_color-red" text={ noResultText } icon="bx-info-circle"/>
            </Col>
        );
    }
    else {
        return (
            <Col className={parentClass+" searchfielderC"}>
                <div className={className+" searchfielder"} invalid={hasResult?"false":"true"}>
                    <Icon icon="bx-filter" onClick={(e)=>{openFilters(e.target)}} className="searchIcoL filterLead" />
                    <input type={"text"} placeholder={label} value={value} onInput={inputed} name={name} id={name} style={style} onFocus={focusAction} onBlur={blurAction} onClick={onClick} className={className+" field"} {...rest}/>
                    <Icon icon="bx-search" className="searchIcoR" onClick={search} />
                </div>
                <div className="filterElementsWrapper" onMouseOut={mouseOuted}>
                    <Text content={"Chercher par:"} className="tl_color-white tl_color-grey tl_fs-12"/>
                    {
                        filterData?.map((dt,index)=>{
                            return (
                                <span key={"flt_"+index} className="filterElement" data-value={dt.value} onClick={(e)=>{onFilter(e.target)}}>{dt.name}</span>
                            )
                        })
                    }
                </div>
                <Notice isActive={hasResult?false:true} className="tl_color-red" text={ noResultText } icon="bx-info-circle"/>
            </Col>
        )
    }
}

export function TextField ({type="text",value="",parentClass="",isValid=true,invalidText="invalid",name="",style={},label="label",className='',onFocus=()=>{},onBlur=()=>{},onInput=()=>{},onClick=()=>{},isRequired=false,pswdIcon=false,...rest}) {
    
    // fonctions par rapport aux inputs en générale
    function focusAction (ev) {
        let e = ev.target
        let father = e.thisParent()
        if (!father.hasAttribute("focusmod")) {
            father.setAttribute("focusmod","true")
        }
        if (!father.hasAttribute("fillmod")) {
            father.setAttribute("fillmod","true")
        }
        onFocus(e,ev)
    }
    function blurAction (ev) {
        let e = ev.target
        let father = e.thisParent()
        father.removeAttribute("focusmod")
        if (!e.value.length > 0) {
            father.removeAttribute("fillmod")
        }
        onBlur(e,ev)
    }

    // Par rapport à l'icône de vu de mot de passe
    function showAction (ev) {
        let e = ev.target
        let inp = e.previousElementSibling

        if (inp.type !== "password") {
            inp.type = "password"
            e.replaceClass("bx-low-vision","bx-show-alt")
        }
        else {
            inp.type = "text"
            e.replaceClass("bx-show-alt","bx-low-vision")
        }
    }

    function inputed (e) {
        onInput(e.target,e)
    }
    if (value === "" || value === null){
        return (
            <Col className={parentClass}>
                <div className={className+" fielder"} invalid={isValid?"false":"true"}>
                    <label htmlFor={name} className="field-label">{label}</label>
                    {
                        !isRequired ? <input type={type} name={name} id={name} style={style} onInput={inputed} onFocus={focusAction} onClick={onClick} onBlur={blurAction} className={className+" field"} {...rest}/>:<input type={type} onInput={inputed} name={name} id={name} style={style} onFocus={focusAction} onBlur={blurAction} onClick={onClick} className={className+" field"} {...rest} required/>
                    }
                    
                    {
                        type === "password" && pswdIcon ? <i className="bx bx-show-alt viewer" onClick={showAction}></i> : <></>
                    }
                </div>
                <Notice isActive={isValid?false:true} className="tl_color-red" text={ invalidText } icon="bx-info-circle"/>
            </Col>
        );
    }
    else {
        return (
            <Col className={parentClass}>
                <div className={className+" fielder"} invalid={isValid?"false":"true"}>
                    <label htmlFor={name} className="field-label">{label}</label>
                    {
                        !isRequired ? <input type={type} name={name} value={value} id={name} style={style} onInput={inputed} onFocus={focusAction} onClick={onClick} onBlur={blurAction} className={className+" field"} {...rest}/>:<input type={type} onInput={inputed} name={name} value={value} id={name} style={style} onFocus={focusAction} onBlur={blurAction} onClick={onClick} className={className+" field"} {...rest} required/>
                    }

                    {
                        type === "password" && pswdIcon ? <i className="bx bx-show-alt viewer" onClick={showAction}></i> : <></>
                    }
                </div>
                <Notice isActive={isValid?false:true} className="tl_color-red" text={ invalidText } icon="bx-info-circle"/>
            </Col>
        )
    }
}
export function TextAreaField ({type="text",value="",parentClass="",isValid=true,invalidText="invalid",name="",style={},label="label",className='',onFocus=()=>{},onBlur=()=>{},onInput=()=>{},onClick=()=>{},isRequired=false,pswdIcon=false,...rest}) {
    
    // fonctions par rapport aux inputs en générale
    function focusAction (ev) {
        let e = ev.target
        let father = e.thisParent()
        if (!father.hasAttribute("focusmod")) {
            father.setAttribute("focusmod","true")
        }
        if (!father.hasAttribute("fillmod")) {
            father.setAttribute("fillmod","true")
        }
        onFocus(e,ev)
    }
    function blurAction (ev) {
        let e = ev.target
        let father = e.thisParent()
        father.removeAttribute("focusmod")
        if (!e.value.length > 0) {
            father.removeAttribute("fillmod")
        }
        onBlur(e,ev)
    }

    // Par rapport à l'icône de vu de mot de passe
    // function showAction (ev) {
    //     let e = ev.target
    //     let inp = e.previousElementSibling

    //     if (inp.type !== "password") {
    //         inp.type = "password"
    //         e.replaceClass("bx-low-vision","bx-show-alt")
    //     }
    //     else {
    //         inp.type = "text"
    //         e.replaceClass("bx-show-alt","bx-low-vision")
    //     }
    // }

    function inputed (e) {
        onInput(e.target,e)
    }
    if (value === "" || value === null){
        return (
            <Col className={parentClass}>
                <div className={className+" fielder"} invalid={isValid?"false":"true"}>
                    <label htmlFor={name} className="field-label">{label}</label>
                    {
                        !isRequired ? <textarea type={type} name={name} id={name} style={style} onInput={inputed} onFocus={focusAction} onClick={onClick} onBlur={blurAction} className={className+" field"} {...rest}></textarea>:<textarea type={type} name={name} id={name} style={style} onInput={inputed} onFocus={focusAction} onClick={onClick} onBlur={blurAction} className={className+" field"} {...rest} required></textarea>
                    }
                </div>
                <Notice isActive={isValid?false:true} className="tl_color-red" text={ invalidText } icon="bx-info-circle"/>
            </Col>
        );
    }
    else {
        return (
            <Col className={parentClass}>
                <div className={className+" fielder"} invalid={isValid?"false":"true"}>
                    <label htmlFor={name} className="field-label">{label}</label>
                    {
                        !isRequired ? <textarea type={type} value={value} name={name} id={name} style={style} onInput={inputed} onFocus={focusAction} onClick={onClick} onBlur={blurAction} className={className+" field"} {...rest}></textarea>:<textarea type={type} name={name} value={value} id={name} style={style} onInput={inputed} onFocus={focusAction} onClick={onClick} onBlur={blurAction} className={className+" field"} {...rest} required></textarea>
                    }
                </div>
                <Notice isActive={isValid?false:true} className="tl_color-red" text={ invalidText } icon="bx-info-circle"/>
            </Col>
        )
    }
}
let nTime = 0;
let noTime = 0
var initialIndex 
export function NumField ({style={},isValid=true,value="",nsibling=1,canUseInput=false,invalidText="invalid",options=[],className='',name="",globalName="",label="label",onFocus=()=>{},onBlur=()=>{},onInput=()=>{},onClick=()=>{},isRequired=false}) {
    let selVal
    let numVal
    if (value.length > 0) {
        selVal = value.split(" ")[0]
        numVal = value.split(" ")[1]
    }
    else {
        selVal = "+229"
        numVal = ""
    }
    
    let [indic,setIndic] = useState(selVal);
    // fonctions par rapport aux inputs en générale
    
    function focusAction (ev) {
        if ( nTime===0 ) {
            if (value.length > 0) {
                ev.target.value = numVal.slice(0,8).replace(/(\d{2})(?=\d)/g, '$1 ');
            }
        }
        nTime++
        let e = ev.target
        let father = e.thisParent()
        if (!father.hasAttribute("focusmod")) {
            father.setAttribute("focusmod","true")
        }
        if (!father.hasAttribute("fillmod")) {
            father.setAttribute("fillmod","true")
        }
        // inputed(ev)
        onFocus(e.thisParent().QSst("[type='hidden']"),e)
    }
    function blurAction (ev) {
        let e = ev.target
        let father = e.thisParent()
        father.removeAttribute("focusmod")
        if (!e.value.length > 0) {
            father.removeAttribute("fillmod")
        }
        onBlur(e.thisParent().QSst("[type='hidden']"),e)
    }

    function inputed (e) {
        let hid = e.target.thisParent().QSst("[type='hidden']")
        hid.value = indic+" "+e.target.value.replace(/\D/g, '')
        onInput(hid,e.target)
        // console.log(hid.value)
    }
    function selectAction (el,hidelement) {
        let e = hidelement.nextElementSibling.nextElementSibling
        hidelement.value = el.value+" "+e.value.replace(/\D/g, '')
        // onInput(hidelement,e)
        // console.log(noTime)
        if (noTime <= 2*nsibling) {
                if (canUseInput){
                    onInput(hidelement,e)
                }
            }
            else {
                onInput(hidelement,e)
        }
          
    }
    function generalAction (ele,globaval) {
        setIndic(ele.value);
        selectAction(ele,globaval)
        
    }
    return (
        <Col>
            <div className={className+" numfielder"} invalid={isValid?"false":"true"}>
                <label className="field-label" htmlFor={name}>{label}</label>
                {isRequired?<input type="hidden" id={name} name={name} required/>:<input type="hidden" id={name} name={name}/>}
                <div className="select-block">
                    <CountryDropDownMenu usedInput={onInput} nsibling={nsibling} canUseInput={canUseInput} options={options} value={indic} className="tl_color-white" onSelected={(ele,globaval)=>{generalAction(ele,globaval)}} name="country"/>
                </div>
                {
                    !isRequired ? <input type="tel" style={style} onFocus={focusAction} onBlur={blurAction} onClick={onClick} className={className+" field"} onInput={inputed}/>:<input type="tel" style={style} onFocus={focusAction} onBlur={blurAction} onInput={inputed} onClick={onClick} className={className+" field"} required/>
                }
            </div>
            <Notice isActive={isValid?false:true} className="tl_color-red" text={ invalidText } icon="bx-info-circle"/>
        </Col>
    );
}

function CountryDropDownMenu ({className="",usedInput=()=>{},nsibling=1,canUseInput=false,value="",name="",onSelected=()=>{},options=[]}) {
    let dataC = options
    
    let [currentIndex,setCurrentIndex] = useState(0);
    useEffect (() => {
        setTimeout(() => {
            if (value !== "") {
                dataC.forEach((va, index) => {
                    if (va["value"] === value) {
                        if (noTime === 0) {
                            initialIndex =index
                            setCurrentIndex(index)
                            setTimeout(() => {
                                Qs('.selhidded').forEach((ele) => {ele.click()})
                            }, 100);
                        }
                        noTime++
                    }
                })
            }
        }, 500);
    },[])
    let selectEvent = new Event("select")
    // let inpEvent = new Event("click")
    function onlocalInput (e) {
        // tl_alert("ert","tout marche bien apparemment")
        
        e.target.value = dataC[initialIndex].value
        let hidelement = e.target.thisParent(2).previousElementSibling
        let ine = hidelement.nextElementSibling.nextElementSibling
        hidelement.value = e.target.value+" "+ine.value.replace(/\D/g, '')
        onSelected(e.target,hidelement)
        nsibling = nsibling === 0?1:nsibling
        nsibling = Math.abs(nsibling)
        // if (noTime <= 5*nsibling) {
        //     if (canUseInput){
        //         usedInput(hidelement,ine)
        //     }
        // }
        // else {
        //     usedInput(hidelement,ine)
        // }
        
    }
    function selected (ev,index) {
        let option = ev.target
        initialIndex =index
        setCurrentIndex(index)
        option.thisParent(2).QSst('[name="country"]').click()

        option.dispatchEvent(selectEvent);

    }   
    // function checkDropState (downer) {
    //     if (downer.getAttribute("hid") === "false") {
    //         downer.setAttribute("hid","true")
    //         downer.styleThis("height",`0px`);
    //     }
    //     else {
    //         downer.setAttribute("hid","false")
    //         var children = downer.children.length;
    //         var maxH = children * 60; // 60px par enfant
    //         downer.styleThis("height",`${maxH}px`);
    //     }
    // }
    function dropDownMenu (ev) {
        // let dropdowner = ev.target
        // let downer = dropdowner.nextElementSibling
        // checkDropState(downer)
        // Qs('#root',"click",(e)=>{
        //     checkDropState(downer)
        // },true)
    }

    return (
        <div className={className + " countrydropdow"} >
            <input type="hidden" className="selhidded" onClick={onlocalInput} name={name}/>
            <div className="dropper_btn" onClick={dropDownMenu}>
                <img className="flag showing-flag" src={dataC[currentIndex].src} alt={dataC[currentIndex].code+" flag"} width="30" height="26"/>
                <div className="indic">{dataC[currentIndex].value}</div>
            </div> |
            <div className="options-bloc" hid="true">
                {
                    dataC?.map((ele,index)=>{
                        return (
                            <menu-option key={ele.code}  value={ele.code} data-code={ele.value} onClick={(e)=>{selected(e,index)}}>
                                <div className="menu-flag-bloc"><img className="flag" alt={ele.code+" flag"} src={ele.src} width="30" height="26"/> {ele.value} </div>
                                <div className="optionName"> {ele.name} ( {ele.code} )</div>
                            </menu-option>
                        )
                    })
                }
            </div>
        </div>
    )

}

export function DropDownMenu ({className="",label="label",isValid=true,haveImage=false,name="",onSelected=()=>{},options=[]}) {
    // options = ;
    let [currentIndex,setCurrentIndex] = useState(0);
    let selectEvent = new Event("select")
    // let inpEvent = new Event("click")
    function onlocalInput (e) {
        // tl_alert("ert","tout marche bien apparemment")
        onSelected(e.target,e.target.thisParent(2).previousElementSibling)
    }
    function selected (ev,imgSrc,indic,index) {
        let option = ev.target
        setCurrentIndex(index)
        // option.thisParent(2).QSst('[type="hidden"]').value = indic
        option.thisParent(2).QSst('[type="hidden"]').value = options[index].value
        setTimeout(() => {
            option.thisParent(2).QSst('[type="hidden"]').click()
        }, 50);
        // let indicEle = option.thisParent(2).QSs('indic')

        // indicEle.innerText = indic+" "
        // if (haveImage) {
        //     let flaged = option.thisParent(2).QSs('showing-flag')
        //     flaged.src = imgSrc
        // }
        

        option.dispatchEvent(selectEvent);

    }   
    function checkDropDownState (downer) {
        if (downer.getAttribute("hid") === "false") {
            downer.setAttribute("hid","true")
            downer.styleThis("height",`0px`);
        }
        else {
            downer.setAttribute("hid","false")
            var children = downer.children.length;
            var maxH = children * 60; // 60px par enfant
            // console.log(children)
            downer.styleThis("height",`${maxH}px`);
        }
    }
    function dropDown (ev) {
        let dropdowner = ev.target
        let downer = dropdowner.nextElementSibling
        checkDropDownState(downer)
        Qs('#root',"click",(e)=>{
            checkDropDownState(downer)
        },true)
    }

    return (
        <div className={className+" dropfielder"} invalid={isValid?"false":"true"} focusmod="true" fillmod="true">
            <label className="field-label" htmlFor={name}>{label}</label>
            <div className={className + "tl_pv-5 tl_ph-5 dropdow"} >
                <input type="hidden" onClick={onlocalInput} name={name} value={options[currentIndex].value}/>
                <div className="dropper_btn" onClick={dropDown}>
                    <Icon icon="bx-chevron-down" className="tl_fs-18" style={{color: "var(--app-secondary-color)"}}/>
                    {/* <i className="bx bx-chevron- tl_fs-18 tl_color-green" > </i> */}
                    {haveImage??<img className="flag showing-flag" src={options[currentIndex].src} alt={options[currentIndex].name} width="30" height="26"/>}
                    <div className="indic">{options[currentIndex].name}</div>
                </div>
                <div className="options-bloc" hid="true">
                    {
                        options?.map((ele,index)=>{
                            return (
                                <menu-option key={ele.value}  value={ele.value} data-value={ele.value} onClick={(e)=>{selected(e,ele.src,ele.value,index)}}>
                                    {haveImage??<div className="menu-flag-bloc"><img className="flag" src={ele.src} alt={ele.name} width="30" height="26"/> {ele.value} </div>}
                                    <div className="optionName"> {ele.name}</div>
                                </menu-option>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        
    )

}

export function SubmitButton ({type='submit',subType="",name="",value="",text="button",style={},id="",className="",onClick=()=>{},onHover=()=>{}}) {

    return (
        <button type={type} id={id} sub-type={subType} name={name} value={value} style={style} className={className + " btn-sub"} onClick={onClick} onMouseOver={onHover}>
            {text === ""?"button":text }
            <squard-loader></squard-loader>
        </button>
    )

}


function Notice ({className="",text="",style={},icon="",isActive=false}) {
    return (
        <div notice-active={isActive?"true":"false"} className={ className+" notice" } style={style}><i className={ "bx "+icon }></i> {text} </div>
    )
}

export function Icon ({icon="bx-star",haveBull=false,bullValue=0,className="",style={},onClick=()=>{},onHover=()=>{},...rest}) {
    return (
        <i className={ className+" tl_position-relative bx "+icon } style={style} onClick={onClick} onMouseOver={onHover} {...rest} >
           { 
            haveBull?<span className="notif-bull circular centry" style={{fontSize:"10px",backgroundColor:"var(--app-secondary-text-color)"}}>
                {bullValue}
            </span>
            :<></>
            }
        </i>
    )
}
export function Text ({content,style={},className=""}) {
    return (
        <div className={className} style={style}>{content}</div>
    );
}
export function Title ({calibre=3,content="",style={},className="",...rest}) {
    return (
        <>
            {
                calibre === 5 ? (
                    <h5 className={className} style={style} {...rest}>{content}</h5>
                ) : (
                    calibre === 3 ? (
                        <h3 className={className} style={style} {...rest}>{content}</h3>
                    ) : (
                        calibre === 4 ? (
                            <h4 className={className} style={style} {...rest}>{content}</h4>
                        ) : (
                            calibre === 2 ? (
                                <h2 className={className} style={style} {...rest}>{content}</h2>
                            ) : (
                                calibre === 1 ? (
                                    <h1 className={className} style={style} {...rest}>{content}</h1>
                                ) : <></>
                            )
                        )
                    )
                )
            }
        </>
          
    );
}
export function TextAction ({content,style={},className="",onTouch=()=>{},...rest}) {
    function touched (e) {
        onTouch(e)
    }
    return (
        <span className={className+" tl_cursor-pointer"} style={style} onClick={touched} {...rest}>{content}</span>
    );
}

export function FullContainer ({children,className="",style={},onClick,onHover, ...rest}) {

    return (
        <div className={className+" fullContainer"} onClick={onClick} onMouseOver={onHover} style={style} {...rest}>
            {children}
        </div>
    )

}
export function ListeTile ({ children ,onClick=()=>{},onHover=()=>{},onLongPress=()=>{}, className="",style={},...rest}) {
    return (
      <div className={"list_tile "+className} onClick={onClick} onContextMenu={onLongPress} onMouseOver={onHover} style={style} {...rest}>
        {children}
      </div>
    );
};

export function WhatsAppButton({ children, action="",onClick=()=>{},onHover=()=>{},onLongPress=()=>{}, className="",style={},...rest }) {
    // const encodedMessage = encodeURIComponent(message); // Encode le message pour qu'il soit compris dans l'URL
    // const whatsappUrl = `https://wa.me/${number}?text=${encodedMessage}`;

    return (
        <a className="wabtn" href={action} target="_blank" rel="noopener noreferrer" onClick={onClick} onContextMenu={onLongPress} onMouseOver={onHover} style={style} {...rest}>
            {children}
        </a>
    );
}

export function Copy ({ content="" ,onClick=()=>{},onHover=()=>{},onLongPress=()=>{}, className="",style={},...rest}) {
  
    async function whenLongPressed (ev) {
        let myToast = new Toast()
        myToast.init()
        let selfElement = ev.target
        if (await copyAdress(selfElement.innerText)) {
            myToast.show("<i class='bx bx-check-circle'></i> text copié !",3500,true)
        }
        onLongPress(ev.target)
    }
    return (
      <span className={"tl-copy "+className} onClick={onClick} onContextMenu={whenLongPressed} onMouseOver={onHover} style={style} {...rest}>
        {content}
      </span>
    );
  };