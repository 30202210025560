
export function Center ({ children , className="",style={},...rest  }) {
    return (
      <div className={"centry "+className} style={style} {...rest}>
        {children}
      </div>
    );
};
export function Flex ({ children , className="",style={},...rest}) {
  return (
    <div className={"flex "+className} style={style} {...rest}>
      {children}
    </div>
  );
};
export function Row ({ children , className="",style={},...rest}) {
    return (
      <div className={"row "+className} style={style} {...rest}>
        {children}
      </div>
    );
};
export function Col ({ children , className="",style={},...rest}) {
    return (
      <div className={"col "+className} style={style} {...rest}>
        {children}
      </div>
    );
};
export function Col1 ({ children , className="",style={},...rest}) {
    return (
      <div className={"col-1 "+className} style={style} {...rest}>
        {children}
      </div>
    );
};
export function Col2 ({ children , className="",style={}, ...rest}) {
    return (
      <div className={"col-2 "+className} style={style} {...rest}>
        {children}
      </div>
    );
};
export function Col3 ({ children , className="",style={}, ...rest}) {
    return (
      <div className={"col-3 "+className} style={style} {...rest}>
        {children}
      </div>
    );
};
export function Col4 ({ children , className="",style={}, ...rest}) {
    return (
      <div className={"col-4 "+className} style={style} {...rest}>
        {children}
      </div>
    );
};
