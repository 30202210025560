

import { useEffect, useState } from "react";
import "./notificationView.css";
import { JSSend, Qs } from "../../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { Center, Col, Row } from "../../widgets/layout_orient";
import { FullContainer, Icon, Text, Title } from "../../widgets/widgets";
import { pop_up_notif_detail } from "../../function";
import { useUserData } from "../../userContext";

function NotifBuilder ({data,onClick=()=>{}}) {
    return (
        <FullContainer onClick={onClick} className="notifContainer" style={{padding:"5px"}}>
            <Row className="tl_gap-10">
                <Center className="notifLeading">
                    <Icon icon="bxs-message" haveBull={data.view === "no"} bullValue={1}/>
                </Center>
                <Col className="tl_gap-5">
                    <Title calibre={4} className="tl_capitalize" content={data.title}/>
                    <p className="ellipis-2 tl_fs-12">{((((data.content.replace("&#039;","'")).replace(/<span class='tl-copy'>/g,'')).replace(/<\/span>/g,'')).replace(/\[copy\]/g,'')).replace(/\[\/copy\]/g,'')}</p>
                    <Text content={data.date}  className="tl_w-fit-content tl_fs-10 tl_mr-20 notifdate"/>
                </Col>
            </Row>
        </FullContainer>
    )
}

// let gtTime=0
let bcl
export function NotificationView ({screenClass="mainScreenNoafter"}) {
    let [notifMap,setnotifMap] = useState([])
    const [userData,] = useUserData({})
    useEffect(()=>{
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/getter.php",
            {"g_n":true,"u_id":userData.id},
            (result,response)=>{
                if (result) {
                    const reslt = response.result
                    if (typeof reslt === "string") {
                        // console.log(response.result)
                    }
                    else {
                        setnotifMap(reslt)
                    }
                    
                }
            }
        )
        setTimeout(() => {
            JSSend(
                'POST',
                "https://app.depotbetpro.com/backend/updater.php",
                {"s_v":true,"u_id":userData.id},
                (result,response)=>{
                    if (result) {
                        // console.log(response.result)
                    }
                }
            )
        }, 1000);
        if (true) {
            clearInterval(bcl)
            bcl = setInterval(() => {
                JSSend(
                    'POST',
                    "https://app.depotbetpro.com/backend/getter.php",
                    {"g_n":true,"u_id":userData.id},
                    (result,response)=>{
                        if (result) {
                            const reslt = response.result
                            if (typeof reslt === "string") {
                                // console.log(response.result)
                            }
                            else {
                                setnotifMap(reslt)
                            }
                        }
                    }
                )
            }, 6000);
            // gtTime ++
        }
        Qs("mainScreen").addClass(screenClass)
    },[])
    
    return (
        
            <Col className="notifWrapper">
                {notifMap.length > 0 ? 
                notifMap?.map((dt,index)=>{
                    return (
                        <NotifBuilder onClick={(e)=>{
                            if (e.target.containsClass("tl-copy")) {
                                return;
                            };
                            pop_up_notif_detail(dt,"Notification")}} data={dt} key={"ntf_"+index} />
                    )
                })
                :
                <Col className="tl_h-70vh centry" style={{color: "grey"}}>
                    <Icon icon="bx-bell bx-tada" style={{color: "grey",fontSize: "50px"}}/>
                    <Text content="Aucune notification pour le moment!" />
                </Col>}
            </Col>
    )

}

