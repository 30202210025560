// import logo from './logo.svg';
import { useEffect, useState } from 'react';
import { Qs, Qst, tlGET } from './@assets/tlBootstrap/tlBootstrap/JS/tl-js';
import './App.css';
import './@assets/tlBootstrap/tlBootstrap/CSS/tlBootstrap.min.css';
import { Icon } from './widgets/widgets';
import { Row } from './widgets/layout_orient';
import { useParams,useNavigate,Outlet } from 'react-router-dom';
import { loading } from './function';
import { useUserData } from './userContext';

// http://192.168.1.192:3000
function App() {
  const { action,couponType } = useParams();
  const [userData,] = useUserData({})
  const Nav = useNavigate();
  let [readyC,setReadyC] = useState(false);
  useEffect (()=>{
    if ( userData !== undefined && userData !== null ){
      if ( !userData.hasOwnProperty("reg_umail") && !userData.hasOwnProperty("reg_uname") && !userData.hasOwnProperty("reg_urol") && !userData.hasOwnProperty("reg_utel") ){
        Nav(process.env.PUBLIC_URL,{replace:true})
        return
      }
      else if ( userData.reg_urol === "admin") {
        Nav(process.env.PUBLIC_URL+"/Admin",{replace:true})
        return
      }
    }
    else {
      Nav(process.env.PUBLIC_URL,{replace:true})
      return
    }
    if (localStorage.getItem("them") !== null && localStorage.getItem("them") !== undefined && localStorage.getItem("them") === "light") {
        // elem.replaceClass("bx-moon","bxs-moon")
        Qst('html').setAttribute("lmd","true")
        setTimeout(() => {
          Qs("logoAp").src = process.env.PUBLIC_URL+"/images/logo_anim_dark.gif"
        }, 500);
    }
    else {
        // elem.replaceClass("bxs-moon","bx-moon")
        Qst('html').removeAttribute("lmd")
        setTimeout(() => {
          Qs("logoAp").src = process.env.PUBLIC_URL+"/images/logo_anim.gif"
        }, 500);
    }
      
    setReadyC(true)
    Qst("global-loader").removeAttribute("inLoad")
  },[])
  async function returnToHome () {
    // window.location.replace(window.location.origin+"/home")
    window.history.pushState(null,null,tlGET.origin+"/home")
    await loading(2000);
    Nav(process.env.PUBLIC_URL+"/home",{replace:true})
    
  }
  async function returnToAdmin () {
    // window.location.replace(window.location.origin+"/Admin")
    window.history.pushState(null,null,tlGET.origin+"/Admin")
    await loading(2000);
    Nav(process.env.PUBLIC_URL+"/Admin",{replace:true})
  }
  return (
    <div className="App">
      
      {/* <h1 className='tl_text-center tl_color-blue'>Hello everybody</h1> */}
      <div className="operaScreen">
        <div className='top-navigation-bar'>
          <div className='nav-top'>
            {
              action !== "AdminNotify"?
              <Row className='app-leading tl_gap-20'>
                  <Icon onClick={returnToHome}  icon="bx-chevron-left" className="leadingIco tl_cursor-pointer"/>
                  <div className='app-Named'> { action==="withdraw"?"Retrait "+couponType:"Recharge "+couponType } </div>
              </Row>
              :
              <Row className='app-leading tl_gap-20'>
                  <Icon onClick={returnToAdmin}  icon="bx-chevron-left" className="leadingIco tl_cursor-pointer"/>
                  <div className='app-Named'> { "Envoyer de notification" } </div>
              </Row>
            }
            <img alt="imag" src={process.env.PUBLIC_URL+"/images/logo_anim.gif"} className='logoAp' width={100} height={100}/>
            {/* <Icon  icon="bxs-cog" className="threaling tl_fs-22 tl_cursor-pointer"/> */}
          </div>

        </div>
        { readyC ? <Outlet></Outlet> : <></> }
      </div>
    </div>
  );
}

export default App;
