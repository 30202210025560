
import { useEffect } from "react";
import { useUserData } from "./userContext";
import { Row } from "./widgets/layout_orient";
import { Icon } from './widgets/widgets';
import { Qst } from "./@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
import { AdminNotify } from "./Screens/adminNotify";

import "./Admin.css";
import { loading } from "./function";
// Array.prototype.filtre = function (key,value) {
//    let newArray = []
//    if (value === "*" ) {
//        newArray = this
//    }
//    if (value === 0) {
//        newArray = this
//    }
//    else {
//        this.forEach((val,index)=>{
//            if (value === this[index][key]) {
//                newArray.push(val)
//            }
//        })
//    }
//    return newArray;
// }
export default function Notifier () {
    const nav = useNavigate()
    // const { action } = useParams();
    const [userData,] = useUserData({})
    // const location = useLocation();
    // let uId = 0
    // const queryString = new URLSearchParams(location.search);
    // uId = queryString.get('userId') || 0;
    useEffect (()=>{
        setTimeout(() => {
            Qst("global-loader").removeAttribute("inLoad")
        }, 1500);
        // console.log(userData.hasOwnProperty(reg_umail) && userData.hasOwnProperty(reg_uname) && userData.hasOwnProperty(reg_utel))
        if ( !userData.hasOwnProperty("reg_umail") && !userData.hasOwnProperty("reg_uname") && !userData.hasOwnProperty("reg_utel")){
            nav("../",{replace:true})
        }
    },[])
   
   const Nav = useNavigate();

    async function returnToAdmin () {
        await loading(2000);
        Nav("/Admin",{replace:true})
    }
   return (
        <Row className="adminScreen" lmd="">
            <div className='top-navigation-bar'>
                <div className='nav-top'>
                    <Row className='app-leading tl_gap-20'>
                        <Icon onClick={returnToAdmin}  icon="bx-chevron-left" style={{color:"black"}} className="leadingIco tl_cursor-pointer"/>
                        <div className='tl_fw-bold tl_fs-18'> { "Envoyer de notification" } </div>
                    </Row>
                    <img alt="imag" src={process.env.PUBLIC_URL+"/images/logo_anim_dark.gif"} width={100} height={100}/>
                    {/* <Icon  icon="bxs-cog" className="threaling tl_fs-22 tl_cursor-pointer"/> */}
                </div>
            </div> 
            <AdminNotify />
        </Row>
   );
};
