
import { Copy, FullContainer,Icon,SearchField,Text,Title } from "../../widgets/widgets"
import { Col,Row,Col2, Center } from "../../widgets/layout_orient"
import { Qs,copyAdress } from "../../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import "./historicView.css";
import { useEffect,useState } from "react";
import { pop_up_detail } from "../../function";
import { useUserData } from "../../userContext";

function CouponLeading ({logo=""}) {
    
    return  (
        <div className='coupon-leading'>
            {logo === "betwinner" ? 
                <div><img alt="betwinner" width='75' height='35'  src={process.env.PUBLIC_URL+"/images/betwinner.png"}/></div>
            :<></>}
            {logo === "1win" ? 
                <div><img alt="1win" width='50' height='20'  src={process.env.PUBLIC_URL+"/images/1win2.png"}/></div>:<></>}
            {logo === "melbet" ?
                <div><img alt="melbet" width='60' height='25'  src={process.env.PUBLIC_URL+"/images/melbet.png"}/></div>:<></>}
            {logo === "1xbet" ? <div><img alt="1xbet" width='45' height='20'  src={process.env.PUBLIC_URL+"/images/1xbet(2).png"}/></div>:<></>}
        </div>
    )

}

function HistoricBuilder ({transid = "",onClick=()=>{},coupon,event,eventOpe,status,montant,eventColor,compteId,numero,statuColor,date,...rest}) {
    let bullClass
    const statuClass = {
        "validé":"bull-validate",
        "rejeté":"bull-reject",
        "attente":"bull-attente",
        "unfinish":"bull-unfinish"
    }
    if ( transid === "unfinish" ) {
        bullClass = statuClass[transid]
    }
    else {
        bullClass = statuClass[status]
    }
    return (
        <FullContainer className="historicContainer tl_position-relative" onClick={onClick} style={{padding:"5px"}} {...rest}>
            <span className={"bull circular centry "+bullClass} style={{fontSize:"10px"}}></span>
            <Row className="tl_gap-10">
                <Center className="tl_p-5-10 tl_max-w-25 tl_min-w-25 histoLeading tl_position-relative">
                    <CouponLeading logo={coupon}/>
                </Center>
                <Col className="tl_justify-content-spaceBetween tl_pb-5">
                    <Row className="tl_gap-40 tl_align-items-center">
                        <Title content={event} className=" tl_capitalize" calibre={4}/>
                        <Text content={eventOpe+montant+' F'} style={{color:eventColor}} className="soldeBloc"/>
                    </Row>
                    <Row className="tl_align-items-center rmd tl_gap-20">
                        <Center>
                            <Text content={"Opé.ID/Num: "} className="tl_fs-10 tl_fw-bold"/>
                            <Copy content={compteId+" / "+numero} style={{color:"var(--app-primary-color)"}} className="tl_fs-10 tl_ml-5"/>
                        </Center>
                        {/* <Center>
                            <Text content={"Numéro: "} className="tl_fs-10 tl_fw-bold hide-on-mobile"/>
                            <Text content={numero} style={{color:"var(--app-primary-color)"}} className="tl_fs-10 tl_ml-5 notiftext"/>
                        </Center> */}
                    </Row>
                    <Row className="tl_align-items-center rmd-2 tl_justify-content-spaceBetween">
                        <div className="tl_display-flex tl_align-items-center tl_w-fit-content">
                            <Text content={"statu: "} className="tl_fs-10 tl_fw-bold"/>
                            <Text content={status} style={{color:statuColor}} className="tl_fs-10 tl_ml-5"/>
                        </div>
                        <Text content={date} style={{color: "orange"}}  className="tl_w-fit-content tl_fs-10 tl_mr-20"/>
                    </Row>
                </Col>
            </Row>
        </FullContainer>
    )
}
// let timo = 0
export function HistoricView ({haveBull,onSearch=()=>{},screenClass="mainScreenNoafter",dtHisto=[]}) {
    let [currentIndex, setCurrentIndex] = useState(0)
    let [currentFilterKey, setcurrentFilterKey] = useState("")
    // let [currentFilterVal, setcurrentFilterVal] = useState("")
    function searchHisto (ele) {
        let valsearched = ele.value
        onSearch(currentFilterKey,valsearched)
    }
    
    const [userData,] = useUserData({})
    let histoFilter = ["*", "recharge","retrait"]
    function changeView (ele) {
        Qs(".histonav-btn").forEach((item) => {
           item.removeClass("histonav-active")
        })
        if (!ele.containsClass("histonav-active")) {
           ele.addClass('histonav-active')
        }
        setCurrentIndex(parseInt(ele.getData("index")))
    }
    useEffect (()=>{
        Qs("mainScreen").addClass(screenClass)
        setTimeout(() => {
            Qs(".histonav-btn","click",changeView)
            Qs("histonav-active").click()
        }, 500);
    },[])
    let statuColor = {
        "validé":"var(--app-secondary-color)",
        "rejeté":"var(--app-tertio-color)",
        "attente":"",
    }
    let eventColor = {
        "recharge":"var(--app-secondary-color)",
        "retrait":"var(--app-tertio-color)",
    }
    let eventOpe = {
        "recharge":"+",
        "retrait":"-"
    }
    
    let dataFilter = [
        {name:"status",value:"status"},
        {name:"compteId",value:"compteId"},
        {name:"numéro",value:"numero"},
        {name:"coupon",value:"coupon"},
        {name:"montant",value:"montant"},
        {name:"token",value:"token"},
    ]
    return (
        <>
            <Col className="histoNavBar">
                <SearchField onInput={searchHisto} onFiltered={(val)=>{setcurrentFilterKey(val)}} filterData={dataFilter} name="searchHisto" label="Recherche..."/>
                <Row className="histoNav">
                    <Col2 className="histoNav-items"><button data-index="0" className="histonav-btn histonav-active">Tout</button></Col2>
                    <Col2 className="histoNav-items"><button data-index="1" className="histonav-btn">Recharge</button></Col2>
                    <Col2 className="histoNav-items"><button data-index="2" className="histonav-btn">Retrait</button></Col2>
                </Row>
                <div className="navSeperator"></div>
                
            </Col>
            <Col className="histoWrapper">
                {
                    
                    dtHisto.filtre("event",histoFilter[currentIndex]).length > 0 ? dtHisto.filtre("event",histoFilter[currentIndex])?.map(
                        (hist,index)=>{
                            return (
                                <HistoricBuilder transid={hist.trans_id} name={hist.name} onClick={
                                        (e)=>{
                                            if (e.target.containsClass("tl-copy")) {
                                                return;
                                            };
                                            pop_up_detail(userData.id,hist, `Detail de ${hist.event}`,userData.reg_urol,hist.id)
                                        }
                                    } 
                                    key={"hst_"+index} date={hist.date} status={hist.status} compteId={hist.compteId} numero={hist.numero} montant={hist.montant} statuColor={statuColor[hist.status]} eventOpe={eventOpe[hist.event]} coupon={hist.coupon} event={hist.event} eventColor={eventColor[hist.event]}/>
                            )
                        }
                    )
                        
                    :
                    <Col className="tl_h-70vh centry">
                        <Icon icon="bx-history" style={{fontSize: "40px"}}/>
                        <Text content="Historiques vide" />
                    </Col>
                }
            </Col>
        </>
    )

}

