
import { useEffect,useRef,useState } from "react";
import { useUserData } from "../userContext";
import { Col,Row } from "../widgets/layout_orient";
import { FullContainer, Icon, ListeTile, Text, Title } from '../widgets/widgets';
import { HistoricView } from "./Views/historicView";
import { NotificationView } from "./Views/notificationView";
import { ProfilView } from "./Views/profilView";
import { HomeView } from "./Views/homeView";
import { Qst,Qs, JSSend,objectToList, tlGET } from "../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { Link, useLocation, useNavigate  } from "react-router-dom";

import "./home.css";
import { askPerm, loading, tl_confirme } from "../function";

let previousView = 0
let timedC = 0
let globalInv
let rmontSt
let ckk = ''
let cvk = ''
let curH = []
export default function Home () {
   const nav = useNavigate()
   const [userData,setUserData] = useUserData({})
   let [hist, setHist] = useState([
      // {id:1,notifid:1,event:"recharge",status:"attente",compteId:"0125422544585",numero:"+229 65065667",montant:4300,coupon:"betwinner",date:"12-02-2024 11:26:06"},
      // {id:1,notifid:2,event:"retrait",status:"validé",compteId:"0012966954555",numero:"+229 67080223",montant:4100,coupon:"1xbet",date:"23-08-2024 09:22:13"},
      // {id:3,notifid:3,event:"recharge",status:"rejeté",compteId:"0012966954555",numero:"+229 96532861",montant:3650,coupon:"1win",date:"18-03-2024 17:10:00"},
      // {id:1,notifid:4,event:"retrait",status:"attente",compteId:"0235855589966",numero:"+229 64157422",montant:3000,coupon:"betwinner",date:"06-02-2024 05:01:41"},
      // {id:3,notifid:5,event:"retrait",status:"rejeté",compteId:"1025205205899",numero:"+229 61781236",montant:2600,coupon:"betwinner",date:"01-02-2024 13:02:25"},
      // {id:3,notifid:6,event:"recharge",status:"validé",compteId:"1245050505200",numero:"+229 65065667",montant:3100,coupon:"melbet",date:"15-01-2024 11:41:29"},
      // {id:3,notifid:7,event:"recharge",status:"attente",compteId:"1122080808088",numero:"+229 97097202",montant:1300,coupon:"betwinner",date:"12-01-2024 14:23:08"},
      // {id:2,notifid:8,event:"retrait",status:"validé",compteId:"0328440848848",numero:"+229 67080223",montant:2115,coupon:"melbet",date:"10-12-2023 01:24:14"},
      // {id:3,notifid:9,event:"retrait",status:"rejeté",compteId:"2058058555855",numero:"+229 52373841",montant:1350,coupon:"1xbet",date:"01-10-2023 10:50:30"},
   ])
   const [histbullVal,sethistbullVal] = useState(0)
   const [bullVal,setbullVal] = useState(0)
   function getNotifC () {
      JSSend(
         'POST',
         "https://app.depotbetpro.com/backend/getter.php",
         {"c_n":true,"u_id":userData.id},
         (result,response)=>{
             if (result) {
                if (typeof response === "object") {
                   if (response.status) {
                      setbullVal(response.result.length)
                   }
                   else {
                      setbullVal(0)
                   }
                }
                
             }
         }
     )
   }
   function getHist () {
      JSSend(
         'POST',
         "https://app.depotbetpro.com/backend/getter.php",
         {"g_h":true,"u_id":userData.id},
         (result,response)=>{
            if (result) {
               // console.log(objectToList(response.result))
               if (response.status) {
                  curH = objectToList(response.result)
                  sethistbullVal(curH.searched("trans_id","unfinish").length)
                  setHist(curH.searched(ckk,cvk))
                  // 
               }
            }
         }
     )
   }
   function remonteState () {
      clearInterval(rmontSt)
      setTimeout(() => {
         rmontSt=setInterval(() => {
            getNotifC()
            setTimeout(() => {
              getHist()
           }, 500);
        }, 6000);
      }, 500);
   }
   // if ( userData === null || userData === undefined ||  !userData.hasOwnProperty("reg_uname") || !userData.hasOwnProperty("reg_upswd") || !userData.hasOwnProperty("reg_utel") || !userData.hasOwnProperty("reg_umail") || !userData.hasOwnProperty("reg_urol") ) {
   //    nav("/",{replace:true})
   //    return
   // }
   function openNotif () {
      if (currentIndex !== 3){
         setCurrentIndex(3)
         
         window.history.pushState(null,null,tlGET.origin+"/home?scr=n")
         // window.location.replace(tlGET.currentURL.thisURIOnly()+"?scr=n")
      }
      else {
         setCurrentIndex (previousView)
         let urArgg
         if (previousView === 0) {
            urArgg = ""
         }
         else if (previousView === 1) {
            urArgg = "p"
         }
         else if (previousView === 2) {
            urArgg = "h"
         }
         window.history.pushState(null,null,urArgg !== "" ? tlGET.origin+"/home?scr="+urArgg: tlGET.origin+"/home")
         // console.log(tlGET.origin+"/home?scr=")
         // window.location.replace(urArgg !== "" ? tlGET.currentURL.thisURIOnly()+"?scr="+urArgg: tlGET.currentURL.thisURIOnly())
      }
      remonteState()
   }
   const location = useLocation();
   const params = new URLSearchParams(location.search);
   const screen = params.get('scr');
   const useEffectExecuted = useRef(false);
   useEffect (()=>{
      // console.log(screen)
      if (!useEffectExecuted.current) {
         if ( userData === null || userData === undefined ||  !userData.hasOwnProperty("reg_uname") || !userData.hasOwnProperty("reg_utel") || !userData.hasOwnProperty("reg_umail") || !userData.hasOwnProperty("reg_urol") ) {
            nav("/",{replace:true})
            return
         }
         else if ( userData.reg_urol === "admin") {
            nav("/Admin",{replace:true})
            return
         }
         askPerm("")
         if (screen !== undefined && screen != null){
            if (screen === "n" ){
               setTimeout(() => {
                  openNotif()
               }, 500);
            }
            else if (screen === "p" ){
               setTimeout(() => {
                  Qs("prof").click()
               }, 500);
            }
            else if (screen === "h" ){
               setTimeout(() => {
                  Qs("hist").click()
               }, 500);
            }
         }
         
         if (localStorage.getItem("them") !== null && localStorage.getItem("them") !== undefined && localStorage.getItem("them") === "light") {
            Qs('themer').replaceClass("bx-moon","bxs-moon")
            Qst('html').setAttribute("lmd","true")
            setTimeout(() => {
               Qs("logoIm").src = process.env.PUBLIC_URL+"/images/logo_anim_dark.gif"
            }, 500);
         }
         else {
            Qs('themer').replaceClass("bxs-moon","bx-moon")
            Qst('html').removeAttribute("lmd")
            setTimeout(() => {
                Qs("logoIm").src = process.env.PUBLIC_URL+"/images/logo_anim.gif"
            }, 500);
         }
         getNotifC()
         getHist()
         remonteState()
         if (timedC === 0) {
            
            
            globalInv = setInterval(() => {
               JSSend(
                  'POST',
                  "https://app.depotbetpro.com/backend/getter.php",
                  {"u_infos":true,"u_id":userData.id},
                  (result,response)=>{
                     if (result) {
                          if (response.status) {
                              // console.log(response.result)
                              setUserData(response.result)
                          }
                     }
                  }
              )
            }, 10000);
            timedC ++
         }
         setTimeout(() => {
            Qst("global-loader").removeAttribute("inLoad")
         }, 1500);
      }
      
   },[])
   let [currentIndex, setCurrentIndex] = useState(0)
   async function deconnectUser () {
      clearInterval(globalInv)
      if (await tl_confirme("","Vous êtes sur le point de vous déconnectez")) {
         await loading (2000)
         setUserData({})
         nav(process.env.PUBLIC_URL+"/",{replace:true})
      }
   }
   function changeView (ele) {
      
      Qs(".nav-items").forEach((item) => {
         item.removeClass("item-active")
      })
      if (!ele.containsClass("item-active")) {
         
         // ele.addClass('item-active')
      }
      setCurrentIndex(parseInt(ele.getData("view-index")))
      previousView = parseInt(ele.getData("view-index"))
      let indic = Qs("bottom-nav-indicator")
      // const eleTop = ele.offsetTop+"px"
      // const eleLeft = ele.offsetLeft+"px"
      const eleContent = ele.innerHTML
      let tsx = 100*parseInt(ele.getData("view-index"))
      let urArgg
      if (parseInt(ele.getData("view-index")) === 0) {
         urArgg = ""
      }
      else if (parseInt(ele.getData("view-index")) === 1) {
         urArgg = "p"
      }
      else if (parseInt(ele.getData("view-index")) === 2) {
         urArgg = "h"
      }
      window.history.pushState(null,null,urArgg !== "" ? tlGET.origin+"/home?scr="+urArgg: tlGET.origin+"/home")
      // window.location.replace(urArgg !== "" ? tlGET.currentURL.thisURIOnly()+"?scr="+urArgg: tlGET.currentURL.thisURIOnly())
      indic.stylesThis({
         transform: `translateX(${tsx}%)`
      })
      setTimeout(() => {
         indic.htmlThis(eleContent)
      }, 0);
      remonteState()
   }

   useEffect (()=>{
      
      // console.log(userData.hasOwnProperty(reg_umail) && userData.hasOwnProperty(reg_uname) && userData.hasOwnProperty(reg_utel))
      if ( !userData.hasOwnProperty("reg_umail") && !userData.hasOwnProperty("reg_uname") && !userData.hasOwnProperty("reg_utel")){
         nav("../",{replace:true})
      }
      if (userData.reg_uname === 'Thinolord') {
         nav("../Admin",{replace:true})
      }
      setTimeout(() => {
         Qs(".nav-items","click",changeView)
         Qs(".nav-items").forEach((item) => {
            if (item.containsClass("item-active")) {
               item.click()
            }
         })
      }, 500);
   },[])
   
   
   const infosData = [
      {named: userData.reg_uname,},
      {named: "Profil",},
      {named: "Historiques",},
      {named: "Notifications",},
   ]
   function openParam () {
      if (!Qs("homeScreen").hasAttribute('slide-active')){
         Qs("homeScreen").setAttribute('slide-active',"true")
      }
      else {
         Qs("homeScreen").removeAttribute('slide-active')
      }
   }
   
   function sH (k,v) {
      ckk = k
      cvk = v
      setHist(curH.searched(k,v))
      // mutH = varH.searched(k,v)
   }
   function changeThem (e) {
      
      let elem = e.target
      if (elem.containsClass("bx-moon")) {
         elem.replaceClass("bx-moon","bxs-moon")
         Qst('html').setAttribute("lmd","true")
         Qs("logoIm").src = process.env.PUBLIC_URL+"/images/logo_anim_dark.gif"
         localStorage.setItem("them","light")
      }
      else {
         elem.replaceClass("bxs-moon","bx-moon")
         Qst('html').removeAttribute("lmd")
         Qs("logoIm").src = process.env.PUBLIC_URL+"/images/logo_anim.gif"
         localStorage.setItem("them","dark")
      }

   }
   return (
      <Row className="homeScreen">
         <tl-slider>
            <Col className="tl_h-100">
               <div className="userDrawerHeader">
                  <img alt="imag" src={process.env.PUBLIC_URL+"/images/edit.gif"} onClick={()=>{setCurrentIndex(1);openParam();setTimeout(()=>{changeView(Qst('[data-view-index="1"]'))},300) }} width={25} className="editIcon"/>
                  {/* <Icon icon="bx-edit-alt" onClick={()=>{setCurrentIndex(1);openParam();setTimeout(()=>{changeView(Qst('[data-index="1"]'))},300) }} className="editIcon"/> */}
                  <Row className="tile">
                     <div className="user_avatar centry"><img alt="imag" src={process.env.PUBLIC_URL+"/images/user.gif"} width={55}/></div>
                     {/* <div className="user_avatar centry"><Icon icon="bx-user"/></div> */}
                     <div className="before_avatar">
                        <Col className="">
                           <Title content={userData.reg_uname} className="tl_color-white drawer-overflow ellipis"/>
                           <Text content={userData.reg_umail} className="drawer-email tl_color-grey drawer-overflow ellipis"/>
                        </Col>
                     </div>
                  </Row>
                  <Row className="tl_ph-10 tl_align-items-center">
                     <Icon icon="bxs-phone" className="tl_fs-18"/><Text content={": "+userData.reg_utel}/>
                  </Row>
               </div>
               <Row className="tl_ph-10 tl_gap-10 tl_align-items-center tl_mt-10">
                  <Icon icon="bx-moon" onClick={changeThem} className="tl_fs-18 themer tl_cursor-pointer"/><Text content={"Theme mode"}/> 
               </Row>
               <Row className="tl_ph-10 tl_mt-30 tl_mb-10 tl_align-items-center tl_h-18px">
                  <Text content={"Coordonnées"} className="tl_color-grey"/><Icon icon="bxs-chevron-right" className="tl_fs-16 tl_color-grey"/>
               </Row>
               <ListeTile>
                  <div className="tile_leading centry"><Icon icon="bxs-envelope"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"mailto:contact@depotbetpro.com"}>contact@depotbetpro.com</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon icon="bxs-phone"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"tel:+22962727170"}>+229 62 72 71 70</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon icon="bx-map-pin"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={"Abomey-Calavi, Bénin"} className=""/>
                  </div>
               </ListeTile>

               <Row className="tl_ph-10 tl_mt-30 tl_mb-10 tl_align-items-center tl_h-18px">
                  <Text content={"Liens Rapides"} className="tl_color-grey"/><Icon icon="bxs-chevron-right" className="tl_fs-16 tl_color-grey"/>
               </Row>
               <ListeTile>
                  <div className="tile_leading centry"><Icon style={{color:"var(--app-secondary-color)"}} icon="bxs-badge-check"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"https://depotbetpro.com/mentions-legales/"} target="_blank" rel="noopener noreferrer">Mentions Légales</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon style={{color:"rgb(10,10,40"}} icon="bxs-check-shield"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"https://depotbetpro.com/politiques-de-confidentialite/"} target="_blank" rel="noopener noreferrer">Politique de confidentialitée</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon style={{color:"rgb(10,10,40"}} icon="bxs-info-circle"/></div>
                  <div className="before_avatar">
                  <Title calibre={4} content={<Link className="quick-link" to={"https://depotbetpro.com/conditions-generales/"} target="_blank" rel="noopener noreferrer">Condition générale</Link>} className=""/>
                  </div>
               </ListeTile>

            </Col>
            <Col>
               <Text content={"by ©thinolord | version 1.0.6"} className="tl_w-100 tl_mb-15 tl_text-center tl_color-grey" />
               
               <FullContainer className="tl_ph-10 deconn" onClick={deconnectUser}>
                  <Row className="tl_align-items-center deconn_rw">
                     <div className="deconn_avatar centry"><Icon icon="bxs-log-out-circle"/></div><Text content="Déconnexion" className="tl_fw-bold tl_fs-14"/>
                  </Row>
               </FullContainer>
            </Col>
            
         </tl-slider>
         <Col className="mainScreen">
            <div onClick={openParam} className='none-modal'></div>
            <Col className="mainScreenC">
               
               <div className='top-navigation-bar'>
                  <div className='nav-top'>
                     <Row className='leading tl_gap-20'>
                        <img alt="imag" src={process.env.PUBLIC_URL+"/images/logo_anim.gif"} className="logoIm" width={100} height={100}/>
                        <div className='Named ellipis tl_w-100 tl_max-w-100'> {infosData[currentIndex].named} </div>
                        <div className='centry'>
                           <Icon icon={'bxs-bell '+(bullVal > 0 ? "bx-tada":"")} haveBull={bullVal > 0} bullValue={bullVal} className="notifShower" onClick={openNotif}/>
                           <Icon  icon="bxs-cog" onClick={openParam} className="threaling tl_fs-22 tl_cursor-pointer"/>
                        </div>
                     </Row>
                     {/* <img alt="imag" src="../images/bell.gif" onClick={()=>{setCurrentIndex(3)}} className="circular notifShower" width={40} height={40} />
                     <img alt="imag" src="../images/cog.gif" onClick={openParam} className="circular threaling tl_cursor-pointer" width={60} height={64} /> */}
                     
                  </div>
               </div>
               <Row className="screenSlotThumb tl_align-items-start" sst={currentIndex+""}>
                  <Col className="screenSlot homeView">
                     {/* {currentIndex === 0 ? <HomeView /> : <></>} */}
                     <HomeView />
                  </Col>
                  
                  <div className="profilContainer screenSlot">
                     {currentIndex === 1 ? <ProfilView /> : <></>}
                  </div>
                  <FullContainer className="screenSlot histoView">
                     {currentIndex === 2 ? <HistoricView  onSearch={sH} dtHisto={hist}/> : <></>}
                     {/* <HistoricView dtHisto={hist}/> */}
                  </FullContainer>
                  <div className="screenSlot notifScreen">
                     {currentIndex === 3 ? <NotificationView /> : <></>}
                     {/* <NotificationView /> */}
                  </div>
                  
               </Row>
            </Col>
            {/* Bar de navigation bas */}
            <div className='bottom-navigation-bar'>
               <div className='nav'>
                  <div className="tl_cursor-pointer bottom-nav-indicator" onClick={()=>{setCurrentIndex(previousView)}}></div>
                  <button className='nav-items acc item-active' data-view-index="0"> <Col className='tl_gap-5 tl_align-items-center'><Icon icon='bxs-home'/> Acceuil <div className='navindicator'></div></Col> </button>
                  <button className='nav-items prof' data-view-index="1"> <Col className='tl_gap-5 tl_align-items-center'><Icon icon='bxs-user'/> Profil <div className='navindicator'></div></Col> </button>
                  <button className='nav-items hist tl_position-relative' data-view-index="2">{histbullVal > 0 ? <span className={"bull circular centry bull-unfinish"} style={{fontSize:"10px"}}></span> : <></>} <Col className='tl_gap-5 tl_align-items-center'><Icon icon='bx-history'/> Historiques <div className='navindicator'></div></Col></button>
               </div>
            </div>
         </Col>
      </Row>
   );
};
