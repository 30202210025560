
import { useNavigate } from 'react-router-dom'
import { loading,ask_operator } from '../../function'
import { Icon } from '../../widgets/widgets'
import { Row,Col } from '../../widgets/layout_orient'
import './homeView.css'
import { useEffect } from 'react'
import { Qs } from '../../@assets/tlBootstrap/tlBootstrap/JS/tl-js'

export function HomeView () {
   useEffect(()=>{
      if (Qs("mainScreen").containsClass("mainScreenNoafter")) {
         Qs("mainScreen").removeClass("mainScreenNoafter")
      }
      
   },[])
    const navigto = useNavigate()
    async function onChoose (e,cp) {
       let targ_to = e.getAttribute("to")
       await loading (3000)
       if (targ_to === "withdraw"){
         navigto("../App/"+targ_to+"/"+cp.toLowerCase(),{replace: false})
       }
       else {
         navigto("../App/"+targ_to+"/"+cp.toLowerCase()+"/_",{replace: false})
       }
    }
    return (
        <>
           {/* Ici nous avons le banner publicitaire */}
           <header className="appBanner">
           </header>
           {/* La section des différents coupons */}
           <section className="methodBanner">
              <div className="tl_w-100">
                 <Col className="methodItems" data-type="1xBet" onClick={(e)=>{ask_operator(e.target,"<div class='concermned-leading'><img width='45' height='20'  src='"+process.env.PUBLIC_URL+"/images/1xbet(2).png'/></div> Opération","Choisissez l'opération à effectuer (recharge ou retrait)",onChoose)}}>
                    <Row className="tl_justify-content-spaceBetween tl_align-items-center">
                       <div className="concermned">
                           <img width={100+"%"} height={50+"%"} alt='1xbet'  src={process.env.PUBLIC_URL+"/images/1xbet(2).png"}/>
                       </div>
                       <Icon icon="bx-chevron-right" className="gotoIcon"/>
                    </Row>
                 </Col>
              </div>
              <div className="tl_w-100">
                 <Col className="methodItems" data-type="MelBet" onClick={(e)=>{ask_operator(e.target,"<div class='concermned-leading'><img width='45' height='30'  src='"+process.env.PUBLIC_URL+"/images/melbet.png'/></div> Opération","Choisissez l'opération à effectuer (recharge ou retrait)",onChoose)}}>
                    <Row className="tl_justify-content-spaceBetween tl_align-items-center">
                       <div className="concermned">
                        <img width={100+"%"} height={80+"%"} alt='melbet'  src={process.env.PUBLIC_URL+"/images/melbet.png"}/>
                       </div>
                       <Icon icon="bx-chevron-right" className="gotoIcon"/>
                    </Row>
                 </Col>
              </div>
              <div className="tl_w-100">
                 <Col className="methodItems" data-type="BetWinner" onClick={(e)=>{ask_operator(e.target,"<div class='concermned-leading'><img width='70' height='35'  src='"+process.env.PUBLIC_URL+"/images/betwinner.png'/></div> Opération","Choisissez l'opération à effectuer (recharge ou retrait)",onChoose)}}>
                    <Row className="tl_justify-content-spaceBetween tl_align-items-center">
                       <div className="concermned">
                        <img width={100+"%"} height={70+"%"} alt='betwinner'  src={process.env.PUBLIC_URL+"/images/betwinner.png"}/>
                       </div>
                       <Icon icon="bx-chevron-right" className="gotoIcon"/>
                    </Row>
                 </Col>
              </div>
              <div className="tl_w-100">
                 <Col className="methodItems" data-type="1Win" onClick={(e)=>{ask_operator(e.target,"<div class='concermned-leading'><img width='40' height='25'  src='"+process.env.PUBLIC_URL+"/images/1win2.png'/></div> Opération","Choisissez l'opération à effectuer (recharge ou retrait)",onChoose)}}>
                    <Row className="tl_justify-content-spaceBetween tl_align-items-center">
                       <div className="concermned" style={{color: 'var(--app-primary-color)',fontWeight:"bold"}} >
                           <img width={100+"%"} height={60+"%"} alt='1win'  src={process.env.PUBLIC_URL+"/images/1win2.png"}/>
                       </div>
                       <Icon icon="bx-chevron-right" className="gotoIcon"/>
                    </Row>
                 </Col>
              </div>
           </section>
        </>
    ) 

}

