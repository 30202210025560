
import { Col } from "../widgets/layout_orient";
import { FullContainer, SubmitButton,TextAreaField,TextField, Title } from "../widgets/widgets";
import "./adminNotify.css";
import { useParams } from 'react-router-dom';
import { JSSend, Qs, Qst, mapToObject, thisFormData, tl_alert } from "../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { useUserData } from "../userContext";
import { useState } from "react";

export function AdminNotify () {

    const {_,userId,userName } = useParams();
    const [userData,] = useUserData();
    let [validTitle,setValidTitle] = useState(true);
    let [validContent,setValidContent] = useState(true);
    let [realValidTitle,setRealValidTitle] = useState(false);
    let [realValidContent,setRealValidContent] = useState(false);
    function isValidTitle (e) {
        if (e.value.length < 2 && e.value.length > 0 ) {
            setValidTitle(false)
            // console.log("valid name")
        }
        else {
            setValidTitle(true)
            if (e.value.length < 2 ) {
                setRealValidTitle(false)
            }
            else {
                setRealValidTitle(true)
            }
        }
    }
    function isValidContent (e) {
        if (e.value.length < 4 && e.value.length > 0 ) {
            setValidContent(false)
            // console.log("valid name")
        }
        else {
            setValidContent(true)
            if (e.value.length < 4 ) {
                setRealValidContent(false)
            }
            else {
                setRealValidContent(true)
            }
        }
    }
    function whenBlured (e) {
        if (e.value.length <= 0) {
            setValidTitle(true)
            setValidContent(true)
        }
    }
    function checkField (e) {
        e.preventDefault()
        let theForm = e.target.thisParent()
        if (theForm.hasAttribute("inauth")) {
            return
        }
        if (realValidContent && realValidTitle) {
            send (e)
        }
        else {
            if (!realValidContent) {
                setValidContent(false)
            }
            if (!realValidTitle) {
                setValidTitle(false)
            }
            tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                `<div class='row centry'>
                    <i class='bx bx-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                </div> 
                Assurez-vous de bien remplir les champs comme il se doit!`
            )
        }
    }
    function send (e) { 
        e.preventDefault()

        let theForm = e.target.thisParent()
        if (theForm.hasAttribute("inauth")) {
            return
        }
        let thisData = new Map(new thisFormData(theForm).entries())
        let userDt = mapToObject(thisData)
        userDt[Qs('txtAr').name]=Qs('txtAr').value
        userDt["admin_n"]=true
        theForm.setAttribute ("inauth","true")
        // console.log(userDt)
        Qst('process-loader').setAttribute('inLoad',"true")
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/request.php",
            userDt,
            async (result,response)=>{
                if (result) {
                    // console.log(response)
                    if (typeof response === "string") {
                        Qst('process-loader').removeAttribute('inLoad')
                    }
                    if (response.status) {
                        setTimeout(() => {
                            // theForm.removeAttribute ("inauth")
                            Qst('process-loader').removeAttribute('inLoad')
                            Qs('txtAr').value = ""
                            Qs('nTit').value = ""
                            // window.location.reload()
                            tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>Envoie",
                                `<div class='row centry'>
                                    <i class='bx bxs-check tl_fs-22' style='color: var(--app-secondary-color)'></i>
                                </div> 
                                Votre message a bien été envoyé!`,
                            )
                            
                        }, 500);
                    }
                    
                    else {
                        theForm.removeAttribute ("inauth")
                        Qst('process-loader').removeAttribute('inLoad')
                        tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                            `<div class='row centry'>
                                <i class='bx bxs-x-circle tl_fs-22' style='color: var(--app-tertio-color)'></i>
                            </div> 
                            Une erreure s'est produite! Veuillez réessayer`
                        )
                    }
                }
                else {
                    theForm.removeAttribute ("inauth")
                    Qst('process-loader').removeAttribute('inLoad')
                    tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                }
            }
        )
    }

    return (
        <Col className="form-slot centry tl_pt-30 tl_pb-30">
            <FullContainer className="centry tl_flex-column">
                <img alt="paperplane" height={250}  src={process.env.PUBLIC_URL+"/images/paperplane.gif"}/>
                <Title className="notifTitle_" content={userId ==="al"?"Envoyer une notification aux clients":"Envoyer une notification à "+userName} />
            </FullContainer>
            <form className="col notifForm tl_gap-20">
                <TextField className="nTit" isValid={validTitle} onInput={isValidTitle} onFocus={isValidTitle} onBlur={whenBlured} invalidText="au moins 2 caractères" name="notif_title" label="Titre de la notification" isRequired={true}/>
                <TextAreaField isValid={validContent} onInput={isValidContent} onFocus={isValidContent} onBlur={whenBlured} name="notif_content" invalidText="au moins 4 caractères" className="txtAr" style={{resize:"none"}} label="Votre message ici..." isRequired={true}/>
                <input type="hidden" name="u_id" value={userId ==="al"?"*":userId}/>
                <input type="hidden" name="admin_id" value={userData.id}/>
                <SubmitButton type="button" onClick={checkField} text="Envoyer"/>
            </form>
        </Col>
    )
}
