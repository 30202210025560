
import "./clientView.css";


import { FullContainer,Icon,SearchField,Text,Title } from "../../widgets/widgets"
import { Col,Row, Center } from "../../widgets/layout_orient"
import { JSSend, objectToList, tl_infos } from "../../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { useEffect,useState } from "react";
import { chooseAction, loading } from "../../function";
import { useNavigate } from "react-router-dom";

function ClientBuilder ({data,onClick=()=>{},onLockClick=()=>{},...rest}) {
    return (
        <FullContainer className="clientContainer" onClick={onClick} style={{padding:"5px"}} {...rest}>
            <Row className="tl_gap-8 tl_align-items-center">
                <Center className="tl_p-5-10 circular clientLeading tl_position-relative">
                    <UserStatusIcon className={"tl_position-absolute tl_bottom-0 tl_right-0 tl_bg-white circular tl_p-2"} status={data.allowed} onClick={onLockClick}/>
                    <Icon icon="bx-user"/>
                </Center>
                <Col className="tl_gap-2 tl_pb-5">
                    <Title calibre={4} content={data.uname} className="tl_ml-5 ellipis"/>
                    <Row ><Text content={data.umail} className=" tl_ml-5 ellipis"/></Row>
                    <Row ><Text content={""+data.utel} style={{color:"var(--app-secondary-color)"}} className="tl_ml-2 tl_fs-12"/></Row>
                    
                </Col>
                <Row className="tl_display-none tl_gap-5 tl_pb-5 tl_max-w-70px tl_min-w-70px">
                    <UserStatusIcon status={data.allowed} onClick={onLockClick}/>
                    <Icon icon="bx-transfer-alt tl_fs-18 act"/>
                    <Icon icon="bxs-send tl_fs-18 act"/>
                </Row>
            </Row>
        </FullContainer>
    )
}
function UserStatusIcon ({status="yes",className,onClick=()=>{}}) {
    if (status === "yes") {
        return (
            <Icon icon="bxs-lock-open tl_fs-18 act" className={className} onClick={()=>{onClick()}}/>
        )
    }
    else {
        return (
            <Icon icon="bxs-lock tl_fs-18 act" className={className} onClick={()=>{onClick()}}/>
        )
    }
}

let dmide = 0
let tl_sck = ""
let tl_scv = ""
let tl_dtU = []
export function ClientView ({onHistoView=()=>{}}) {
    // let [currentIndex, setCurrentIndex] = useState(0)
    let [currentFilterKey, setcurrentFilterKey] = useState("uname")
    
    let [dataUser, setdataUser] = useState([
        // {id:1,uname:"Thino lord",umail:"thinolo@gmail.com",utel:"+229 44525566",allowed:"yes"},
        // {id:3,uname:"Zinsou Paulin",umail:"zinsoupaulin@gmail.com",utel:"+229 56212478",allowed:"no"},
        // {id:6,uname:"Amareti jenuio lord",umail:"amaretijenuio@gmail.com",utel:"+229 65233544",allowed:"yes"},
    ])
    function getUser (includes=false) {
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/getter.php",
            {"g_u":true},
            (result,response)=>{
                if (result) {
                     // console.log(response.result)
                    //  console.log(objectToList(response.result))
                    tl_dtU = objectToList(response.result)
                    setdataUser(tl_dtU)
                    setUsersData(tl_dtU.searched(tl_sck,tl_scv))
                    if (includes ) {
                        setUsersData(tl_dtU)
                    }
                    dmide++
                    
                }
            }
        )
    }
    let [usersData, setUsersData] = useState(dataUser)
    useEffect(()=>{
        setTimeout(()=>{getUser(true)}, 500);
        if (dmide === 0 ) {
            setInterval(getUser, 6000);
        }
        
    },[])
    
    let dataFilter = [
        {name:"nom",value:"uname"},
        {name:"email",value:"umail"},
        {name:"numéro",value:"utel"},
    ]
    function searchUser (ele) {
        let valsearched = ele.value
        tl_sck = currentFilterKey
        tl_scv = valsearched
        setUsersData(dataUser.searched(currentFilterKey,valsearched))
    }
    function updateAllowed (id,allo) {
        let act
        // Créer une nouvelle version du tableau en mettant à jour l'utilisateur ciblé
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/updater.php",
            {"up_allowed":true,current:allo,"u_id":id},
            (result,response)=>{
                if (result) {
                    // console.log(response.result)
                    act = response.result
                    getUser()
                    const updatedDataUser = usersData.map(user => {
                        if (user.id === id) {
                            let nw = {...user, allowed: act}; // Mettre à jour le champ 'allowed'
                            return nw
                        }
                        return user; // Retourner l'utilisateur inchangé
                    });
                    setUsersData(updatedDataUser); // Mettre à jour l'état avec le nouveau tableau
                    act === "no" ?tl_infos("bloqué/débloqué","<div class='col tl_align-items-center'><img width='250' height='250' src='"+process.env.PUBLIC_URL+"/images/lock.gif'/> <h4>utilisateur bloqué</h4></div>"):
                        tl_infos("bloqué/débloqué","<div class='col tl_align-items-center'><img width='250' height='250' src='"+process.env.PUBLIC_URL+"/images/unlock.gif'/> <h4>utilisateur débloqué</h4></div>")
                }
            }
        )// Mettre à jour l'état avec le nouveau tableau
        
    };
    const navigto = useNavigate()
    async function chooseAct (id,allowed,name) {
        let returned = await chooseAction()
        if (returned.status) {
            if (returned.type === "lock") {
                updateAllowed(id,allowed)
            }
            else if (returned.type === "hist") {
                onHistoView(id)
            }
            else if (returned.type === "notify") {
                await loading(2000)
                navigto("./adminNotify/"+id+"/"+name)
            }
        }
    }
    return (
        <>
            <Col className="clientNavBar">
                <SearchField name="userSearch" onInput={searchUser} onFiltered={(val)=>{setcurrentFilterKey(val);tl_sck = val}} filterData={dataFilter} label="Recherche..."/>
                <div className="navSeperator"></div>
                
            </Col>
            <Col className="clientWrapper">
                {
                    usersData.length > 0 ? usersData?.map((user,index)=>{
                        return (
                            <ClientBuilder onClick={()=>{chooseAct(user.id,user.allowed,user.uname)}} key={"u_"+index} data={user} onLockClick={()=>{updateAllowed(user.id)}}/>
                        )
                    })
                    :
                    <Col className="tl_h-70vh centry">
                        <Icon icon="bx-user" style={{fontSize: "40px"}}/>
                        <Text content="Aucun utilisateur pour le moment!" />
                    </Col>
                }
                
            </Col>
        </>
    )

}



