
import { useNavigate } from 'react-router-dom'
import { loading } from '../../function'
import { Icon, Text, Title } from '../../widgets/widgets'
import { Row,Col } from '../../widgets/layout_orient'
import './adminHomeView.css'
import { useEffect } from 'react'
import { Qs } from '../../@assets/tlBootstrap/tlBootstrap/JS/tl-js'
import { useUserData } from '../../userContext';

export function AdminHomeView ({nHist=0,nUser=0,onClient=()=>{},onTransaction=()=>{}}) {
   useEffect(()=>{
      if (Qs("mainScreen").containsClass("mainScreenNoafter")) {
         Qs("mainScreen").removeClass("mainScreenNoafter")
      }
      
   },[])
   // let [histbull, sethistbull] = useState(0)
    const navigto = useNavigate()
    const [userData,] = useUserData();
    async function onChoose (e,cp) {
       let targ_to = e.getAttribute("to")
       await loading (3000)
       if (targ_to === "adminNotify"){
         navigto("./"+targ_to+"/"+cp,{replace: true})
       }
       else {
         navigto("?userId="+cp,{replace: true})
       }
    }
    return (
        <>
           {/* Ici nous avons le banner publicitaire */}
           <header className="appAdminBanner">
                <img width={100} height={100} alt='admin_ico' src={process.env.PUBLIC_URL+"/images/admin.gif"}/>
                <Col className='tl_gap-10'>
                    <Row className='tl_align-items-center'>
                        <strong className='tl_mr-5'>
                            Nom :
                        </strong>
                        <Text style={{color:"var(--app-secondary-text-color)"}} className='tl_fs-16' content={userData.reg_uname}/>
                    </Row>
                    <Row className='tl_align-items-end tl_h-20px'>
                        <strong className='tl_mr-5'>
                            <Icon icon='bxs-envelope' className='tl_fs-18'/> :
                        </strong>
                        <Text style={{color:"var(--app-secondary-text-color)"}} className='tl_fs-16' content={userData.reg_umail}/>
                    </Row>
                </Col>
           </header>
           {/* La section des différents coupons */}
           <section className="methodBanner">
              <div className="tl_w-100">
                 <Col className="methodItems" data-type="clients" onClick={(e)=>{onClient()}}>
                    {/* <Row className="tl_gap-20 tl_align-items-center"> */}
                       <div className="concermned">
                           <img alt='client' width={50} height={50}  src={process.env.PUBLIC_URL+"/images/group.gif"}/>
                       </div>
                       
                       <Icon icon="bx-chevron-right" className="gotoIcon"/>
                    {/* </Row> */}
                    { 
                     nUser !== 0 ?<span className="homebull circular centry" style={{fontSize:"10px",backgroundColor:"var(--app-secondary-text-color)"}}>
                        {nUser}
                     </span>
                     :<></>
                     }
                    <Title content={"Clients"}/>
                 </Col>
              </div>
              <div className="tl_w-100">
                 <Col className="methodItems" data-type="notif-send" to="adminNotify" onClick={(e)=>{onChoose(e.target,"al")}}>
                    {/* <Row className="tl_gap-20 tl_align-items-center"> */}
                       <div className="concermned">
                           <img alt='send' width={100} height={100}  src={process.env.PUBLIC_URL+"/images/send.gif"}/>
                       </div>
                       
                       <Icon icon="bx-chevron-right" className="gotoIcon"/>
                    {/* </Row> */}
                    <Title className='ellipis tl_w-100 tl_max-w-100 tl_text-center' content={"Envoyer notification"}/>
                 </Col>
              </div>
              <div className="tl_w-100">
                 <Col className="methodItems tl_pt-10" data-type="notif-send" onClick={(e)=>{onTransaction()}}>
                    {/* <Row className="tl_gap-20 tl_align-items-center"> */}
                       <div className="concermned">
                           <img alt='transfert' width={100} height={90}  src={process.env.PUBLIC_URL+"/images/transfert.gif"}/>
                       </div>
                       
                       <Icon icon="bx-chevron-right" className="gotoIcon"/>
                    {/* </Row> */}
                    { 
                     nHist !== 0 ?<span className="homebull circular centry" style={{fontSize:"10px",backgroundColor:"var(--app-secondary-text-color)"}}>
                        {nHist}
                     </span>
                     :<></>
                     }
                    <Title content={"Transactions"}/>
                 </Col>
              </div>
           </section>
        </>
    ) 

}

