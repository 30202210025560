
import { Qst,futureBuilder,createdElement,bodyAppend,bodyRemove,Qs, JSSend, tl_alert, tlGET, Toast, copyAdress } from "./@assets/tlBootstrap/tlBootstrap/JS/tl-js";
// import { BiometricAuth } from "capacitor-biometric-auth";
import { BiometricAuth,BiometryError,BiometryErrorType,AndroidBiometryStrength } from "@aparajita/capacitor-biometric-auth";
import { CallNumber } from 'capacitor-call-number';
// import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor, Plugins } from '@capacitor/core';
// import { json } from "react-router-dom";

export const TLGlobalPlatform = Capacitor.getPlatform();
const getFirstplatform = Capacitor.getPlatform();

// export const addListeners = async () => {
//     const nav = useNavigate()
//     await PushNotifications.addListener('registration', token => {
//         // tl_alert('Registration: ', token.value);
//         // console.log('Registration: '+token.value)
//         setSubscriptionCookie ("tlapp_"+token.value)
//     });
  
//     await PushNotifications.addListener('registrationError', err => {
//     //   alert('Registration error: ', err.error);
//     });
  
//     await PushNotifications.addListener('pushNotificationReceived', async notification => {
//         const { click_action, screen } = notification.data;
        
//         // Vérifier si la notification a été reçue en arrière-plan ou si elle a été cliquée
//         if (notification.additionalData.foreground) {
//             // La notification a été reçue en premier plan, peut-être gérer l'affichage d'une alerte ou autre
//         } else {
//             // La notification a été reçue en arrière-plan, rediriger si elle a été cliquée
//             await PushNotifications.addListener('pushNotificationActionPerformed', action => {
//                 if (action.notification.data.click_action === 'FLUTTER_NOTIFICATION_CLICK' && action.notification.data.screen) {
//                     // Rediriger vers l'URL spécifiée
//                     tl_alert("screen", action.notification.data.screen);
//                     nav(`/home?scr=${action.notification.data.screen}`)
//                     // window.location.href = `/home?scr=${action.notification.data.screen}`;
//                 }
//             });
//         }
//     });
  
    
// }


async function askPush () {
    // Vérifiez si les notifications push sont prises en charge
    const { PushNotifications } = Plugins;
    if (!PushNotifications) {
    //   console.log('Push notifications not supported on this platform');
      return;
    }
  
    // Demande la permission pour les notifications
    const status = await PushNotifications.requestPermissions();
    if (status.receive === 'granted') {
      // Enregistre l'appareil pour les notifications
      PushNotifications.register();
    } else {
    //   console.error('Permission denied for push notifications');
    }
  }
  
// const getDeliveredNotifications = async () => {
//     const notificationList = await PushNotifications.getDeliveredNotifications();
//     console.log('delivered notifications', notificationList);
// }


export async function makeCall (number) {
    try {
        await CallNumber.call({ number,bypassAppChooser:false});
    } catch (error) {
        // console.error("Erreur lors du lancement de l'appel :", error);
    }
};
export function waMeUrl({ number, message }) {
    const encodedMessage = encodeURIComponent(message); // Encode le message pour qu'il soit compris dans l'URL
    return `https://wa.me/${number}?text=${encodedMessage}`;
}
export function getGreeting() {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
        return "Bonjour"; // Salutation du matin
    } else if (currentHour < 18) {
        return "Bon après-midi"; // Salutation de l'après-midi
    } else {
        return "Bonsoir"; // Salutation du soir
    }
}

export async function authenticate () {
    try {
        await BiometricAuth.authenticate({
            reason: 'Pour procéder, confirmez votre identité.',
            cancelTitle: 'Annuler',
            allowDeviceCredential: true,
            iosFallbackTitle: 'Use passcode',
            androidTitle: "confirmation d'identité.",
            androidSubtitle: 'Utilisez votre empreinte digitale pour vous authentifier',
            androidConfirmationRequired: true,
            androidBiometryStrength: AndroidBiometryStrength.weak,
        })
        return {status:true,canceled:false}
    } catch (error) {
        // error is always an instance of BiometryError.
        if (error instanceof BiometryError) {
            if (error.code === BiometryErrorType.userCancel) {
                // Display the error.
                return {status:false,canceled:true}
            }
            else {
                return {status:false,canceled:false}
            }
        }
        else {
            return {status:false,canceled:false}
        }
    }
}

export async function loading (duration=1000,onLoadingStart=()=>{},onLoadingEnd=()=>{}) {
    let e = Qst("global-loader");
    e.setAttribute("inLoad","")
    onLoadingStart(e)
    
    return new Promise(async (resolve)=>{
        await futureBuilder(duration)
        e.removeAttribute("inLoad")
        onLoadingEnd(e)
        resolve()
    })
}

export function ask_operator (element,title="Choisissez l'opération", content,onclose=null) {
    let coupon = element.getData("type")
    if (title === "") {
        title="Choisissez l'opération"
    }
    let modal = createdElement("div",{addid:"operator-modal"})
    let addhtml= `
        <div alert-content="d">
            <h3 alert-content="title">
                ${title}
            </h3>
            <div alert-content="content">
                ${content}
            </div>
            <button class="btn-5 dark-btn" alert-content="ended" to='recharge'>
                <i class='bx bx-money tl_fs-18 tl_mr-5'></i> Recharge
            </button>
            <button class="btn-5 dark-btn" alert-content="ended" to='withdraw'>
                <i class='bx bx-money-withdraw tl_fs-18 tl_mr-5' ></i> Retrait
            </button>
        </div>
        `
    modal.htmlThis(addhtml)
    bodyAppend(modal)
 
    Qs("#operator-modal","click",(e,ev)=>{
        if (ev.target === e) {
            bodyRemove(modal);
        }
    })
    Qst('#operator-modal [alert-content="ended"]',"click",(e,ev)=>{
        if (onclose !== null ) {
            onclose (e,coupon);
        }
        bodyRemove(modal)
    })
    Qst('#operator-modal [alert-content="d"]').setAttribute('alert-set',"")
}

export function chooseAction (title="Que voulez-vous faire?", content,onclose=null) {
    if (title === "") {
        title="Que voulez-vous faire?"
    }
    let modal = createdElement("div",{addid:"operator-modal",})
    let addhtml= `
        <div alert-content="d">
            <h3 alert-content="title" style="color: orange">
                ${title}
            </h3>
            <div alert-content="content" class="chooseC" style="color:white;padding: 10px 0px">
                <div class="row tl_align-items-center tl_justify-content-spaceBetween tl_pb-5">
                    <button data-type="lock" title="débloqué/bloqué" class="tl_display-flex tl_flex-column tl_align-items-center tl_gap-5 chooseBtn">
                        <i class="tl_color-white bx bxs-lock-open tl_fs-18"></i>
                    </button> 
                    <button data-type="hist" title="voir ses transactions" class="tl_display-flex tl_flex-column tl_align-items-center tl_gap-5 chooseBtn">
                        <i class="tl_color-white bx bx-transfer-alt tl_fs-18"></i>
                    </button>
                    <button data-type="notify" title="Envoyer une notification à l'utilisateur" class="tl_display-flex tl_flex-column tl_align-items-center tl_gap-5 chooseBtn">
                        <i class="tl_color-white bx bxs-send tl_fs-18"></i>
                    </button>
                </Row>
            </div>
        </div>
        `
    modal.htmlThis(addhtml)
    bodyAppend(modal)
    return new Promise(function (resolve) {
        Qs("#operator-modal","click",(e,ev)=>{
            if (ev.target === e) {
                bodyRemove(modal);
                resolve({status:false,type:""})
            }
        })
        Qs('.chooseBtn',"click",(e,ev)=>{
            if (onclose !== null ) {
                onclose (e,ev);
            }
            bodyRemove(modal)
            if (e.getData("type") === "lock") {
                resolve({status:true,type:"lock"})
            }
            else if (e.getData("type") === "hist") {
                resolve({status:true,type:"hist"})
            }
            else {
                resolve({status:true,type:"notify"})
            }
            
        })
        Qst('#operator-modal [alert-content="d"]').setAttribute('alert-set',"")
    })
    
}


export function tl_confirme (title="Confirmation", content,onclose=null) {
    if (title === "") {
        title="Confirmation"
    }
    let modal = createdElement("div",{addid:"operator-modal",})
    let addhtml= `
        <div alert-content="d">
            <h3 alert-content="title" style="color: orange">
                ${title}
            </h3>
            <div alert-content="content" style="color:white;padding: 10px 0px">
                ${content}
            </div>
            <button class="btn-5 dark-btn" alert-content="ended" style='color: var(--app-secondary-color)' data-action="ok" >
                <i style="color:var(--app-secondary-color)" class='bx bxs-check-circle tl_fs-18 tl_mr-5'></i> Confirmer
            </button>
            <button class="btn-5 dark-btn tl_color-red" style="color:var(--app-tertio-color)" alert-content="ended" data-action="cancel">
                <i style="color:var(--app-tertio-color)" class='bx bxs-x-circle tl_fs-18 tl_mr-5' ></i> Annuler
            </button>
        </div>
        `
    modal.htmlThis(addhtml)
    bodyAppend(modal)
    return new Promise(function (resolve) {
        Qs("#operator-modal","click",(e,ev)=>{
            if (ev.target === e) {
                bodyRemove(modal);
                resolve(false)
            }
        })
        Qst('#operator-modal [alert-content="ended"]',"click",(e,ev)=>{
            if (onclose !== null ) {
                onclose (e,ev);
            }
            bodyRemove(modal)
            if (e.getData("action") === "ok") {
                resolve(true)
            }
            else {
                resolve(false)
            }
            
        })
        Qst('#operator-modal [alert-content="d"]').setAttribute('alert-set',"")
    })
    
}
export function confirm_edition (title="Authentification", content,id,onclose=null) {
    if (title === "") {
        title="Authentification"
    }
    let modal = createdElement("div",{addid:"operator-modal",})
    let addhtml= `
        <div alert-content="d2">
            <h3 alert-content="title" style="color: var(--app-secondary-color)">
                <i style="color:var(--app-secondary-color)" class='bx bx-shield-quarter tl_fs-18 tl_mr-10'></i> ${title}
            </h3>
            <div alert-content="content2" style="color:white;padding-top: 10px">
                ${content}
                <input type="password" id="auth-psw" style="margin-top:20px;padding: 10px; font-size: 14px;border-bottom: 2px solid rgba(160,160,160);border-right: 2px solid rgba(160,160,160);border-top: 2px solid rgba(50,50,50);border-left: 2px solid rgba(50,50,50);background:transparent;color: var(--ux-grey);border-radius: var(--field-radius);outline:none;" placeholder="entrez votre mot de passe"/>
            </div>
            <div class="row centry">
                <button class="btn-5 dark-btn" alert-content="ended2" style='color: var(--app-secondary-color)' data-action="ok" >
                    <i style="color:var(--app-secondary-color)" class='bx bxs-check-circle tl_fs-18 tl_mr-5'></i> Confirmer
                </button>
                <button class="btn-5 dark-btn tl_color-red" style="color:var(--app-tertio-color)" alert-content="ended2" data-action="cancel">
                    <i style="color:var(--app-tertio-color)" class='bx bxs-x-circle tl_fs-18 tl_mr-5' ></i> Je ne suis pas sûr
                </button>
            </div>
            
        </div>
        `
    modal.htmlThis(addhtml)
    bodyAppend(modal)
    Qst('#operator-modal [alert-content="d2"]').setAttribute('alert-set',"")
    return new Promise(function (resolve) {
        Qs("#operator-modal","click",(e,ev)=>{
            if (ev.target === e) {
                bodyRemove(modal);
                resolve({statu:false,wrongauth:false})
            }
        })
        let inp = Qst('#operator-modal #auth-psw')
        Qst('#operator-modal [alert-content="ended2"]',"click",(e,ev)=>{
            if (onclose !== null ) {
                onclose (e,ev);
            }
            if (e.getData("action") === "ok") {
                JSSend(
                    'POST',
                    "https://app.depotbetpro.com/backend/updater.php",
                    {"u_auth":true,"pswd":inp.value,"u_id":id},
                    (result,response)=>{
                        if (result) {
                            if (response.status) {
                                resolve({statu:true,wrongauth:false})
                            }
                            else {
                                resolve({statu:false,wrongauth:true})
                            }
                            if (onclose !== null ) {
                                onclose (e,ev);
                            }
                            bodyRemove(modal)
                        }
                        else {
                            resolve({statu:false,wrongauth:null})
                            bodyRemove(modal)
                            setTimeout(() => {
                                tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                            }, 500);
                        }
                    }
                )
            }
            else {
                resolve({statu:false,wrongauth:null})
                if (onclose !== null ) {
                    onclose (e,ev);
                }
                bodyRemove(modal)
            }
            
            
        })
    })
    
}
function CouponLogo (logo="") {
    let ctt = ""
    switch (logo) {
        case "betwinner":
            ctt = `<div><img width='100' height='35'  src='${process.env.PUBLIC_URL}/images/betwinner.png'/></div>`
            break
        case "1win":
            ctt = `<div><img width='50' height='30'  src='${process.env.PUBLIC_URL}/images/1win2.png'/></div>`
            break;
        case "melbet":
            ctt = `<div><img width='60' height='35'  src='${process.env.PUBLIC_URL}/images/melbet.png'/></div>`
            break;
        case "1xbet":
            ctt = `<div><img width='60' height='31'  src='${process.env.PUBLIC_URL}/images/1xbet(2).png'/></div>`
            break;
        default:
            break
    }
    return `<div class='coupon-leading'>${ctt}</div>`
}
function statuImage (state="") {
    let ctt = ""
    switch (state) {
        case "attente":
            ctt = `<div class="col tl_align-items-center"><img alt="imag" src="${process.env.PUBLIC_URL}/images/timer2.gif" className="circular" width="80" height="70" /><p class="tl_color-grey">votre opération est en attente</p></div>`
            break
        case "validé":
            ctt = `<div class="col tl_align-items-center"><img alt="imag" src="${process.env.PUBLIC_URL}/images/check.gif" className="circular" width="90" height="50" /><p class="tl_color-green" style="color: var(--app-secondary-color)">votre opération a été vaildée</p></div>`
            break;
        case "rejeté":
            ctt = `<div class="col tl_align-items-center"><img alt="imag" src="${process.env.PUBLIC_URL}/images/x2.gif" className="circular" width="60" height="60" /><p class="tl_color-red" style="color: var(--app-tertio-color)">votre opération a été rejetée</p></div>`
            break;
        default:
            break
    }
    return `<div class='coupon-leading'>${ctt}</div>`
}

// function TLRecharge ({token,cp,modal}) {
//     const navigto = useNavigate()
//     useEffect(() => {
//         navigto("../App/recharge/"+cp.toLowerCase()+"/_?tk="+token,{replace: false})
//         bodyRemove(modal)
//         console.log("should redirect")
//     }, []);
//     return null
// }

export function pop_up_detail (userId,data,title="Détail de ", userlevel="better",u_id,onValidate=null,onclose=null) {
    if (title === "") {
        title="Détail de "
    }
    // let event = title.split(" ")[2]
    let modal = createdElement("div",{addid:"pop_up_detail",})
    let logo = CouponLogo(data.coupon)
    let statement = statuImage(data.status)
    let status = data.status === 'rejeté'?`<span style="color:var(--app-tertio-color)">${data.status}</span>`:(data.status === 'validé'?`<span style="color:var(--app-secondary-color)">${data.status}</span>`:`<span>${data.status}</span>`)
    let enele = userlevel === "admin" && data.status==="attente" ? `tl_display-flex` : `tl_display-none`
    
    let fish = userId === u_id && userlevel !== "admin"  && data.trans_id === "unfinish" ? `tl_display-flex` : `tl_display-none`

    let loc = data.event === "retrait" ? `<div class="row tl_align-items-center tl_mv-5"><strong style="color: var(--app-primary-color);margin-right:10px">Lieu de retrait: </strong> <span class='tl-copy'>${data.location}</span></div>` : ""
    let cod = data.event === "retrait" ? `<div class="row tl_align-items-center tl_mv-5"><strong style="color: var(--app-primary-color);margin-right:10px">Code de retrait: </strong> <span class='tl-copy'>${data.code}</span></div>` : ""
    let transId = data.event === "recharge" ? `<div class="row tl_align-items-center tl_mv-5"><strong style="color: var(--app-primary-color);margin-right:10px">Transaction ID: </strong><span class='tl-copy'>${data.trans_id}</span></div>` : ""
    let addhtml= `
        <div alert-content="logo">
            
        </div>
        <div alert-content="d">
            <i class="bx bx-x tl_fs-20 transition-all-ease-3 tl_cursor-pointer tl_color-red-hover" style="position:absolute;top:-40px;right:10px;"></i>
            <h2 alert-content="title">
                ${title}
            </h2>
            <div alert-content="content" style="font-size: 11px;">
                <div style="border-radius:8px;background:rgb(70,70,90);padding:8px;text-align:center;font-weight:bold" class="tl_mb-5">${logo}</div>
                <div style="background:rgba(0,0,0,0);text-align:center;" class="tl_mb-5">${statement}</div>
                <div class="row tl_align-items-center tl_mv-5 tl_justify-content-spaceBetween"><strong style="color: var(--app-tertio-color)">Solde: </strong> <div style='margin-left: 10px;padding:2px 4px;border-radius: 5px;color:rgb(20,20,40);background-color: rgb(240, 240, 240);font-weight:bold'>${data.montant} F</div></strong></div>
                <div class="row tl_align-items-center tl_mv-5"><strong style="color: var(--app-primary-color);margin-right:10px;text-transform: capitalize">ID ${data.coupon}: </strong> <span class='tl-copy'>${data.compteId}</span></div>
                <div class="row tl_align-items-center tl_mv-5"><strong style="color: var(--app-primary-color);margin-right:10px">Numéro d'opération: </strong> <span class='tl-copy'>${data.numero}</span></div>
                <div class="row tl_align-items-center tl_mv-5"><strong style="color: var(--app-primary-color);margin-right:10px">Nom figurant: </strong> <span class='tl-copy'>${data.name}</span></div>
                ${cod}
                ${loc}
                ${transId}
                <div class="row tl_align-items-center tl_mv-5 ellipis" style="color:var(--app-secondary-color)"><strong style="color: var(--app-primary-color);margin-right:10px">Token: </strong> <span class='tl-copy'>${data.token}</span></div>              
                <div class="row tl_align-items-center tl_mv-5"><strong style="color: var(--app-primary-color);margin-right:10px">Status: </strong> ${status}</div>
                <p class="tl_mt-10"><small style="color:orange;font-size:10px;">${data.date}</small></p>
            </div>
            <div class="row centry ${enele} actionBlock">
                <button class="btn-5 dark-btn" alert-content="ended2" style='color: var(--app-secondary-color)' data-action="validé" >
                    <i style="color:var(--app-secondary-color)" class='bx bxs-check-circle tl_fs-18 tl_mr-5'></i> valider
                </button>
                <button class="btn-5 dark-btn tl_color-red" style="color:var(--app-tertio-color)" alert-content="ended2" data-action="rejeté">
                    <i style="color:var(--app-tertio-color)" class='bx bxs-x-circle tl_fs-18 tl_mr-5' ></i> rejeté
                </button>
            </div>
            <div class="row centry ${fish} actionBlock">
                <button class="btn-5 dark-btn" alert-content="ended2" style='color: var(--app-secondary-color)' data-action="contin" >
                    <i style="color:var(--app-secondary-color)" class='bx bx-chevron-right tl_fs-18 tl_mr-5'></i> continuer l'opération
                </button>
            </div>
            
        </div>
        `
    modal.htmlThis(addhtml)
    bodyAppend(modal)
    Qs('.tl-copy','contextmenu',async (e,ev)=>{
        let myToast = new Toast()
        myToast.init()
        if (await copyAdress(e.innerText)) {
            myToast.show("<i class='bx bx-check-circle'></i> text copié !",3500,true)
        }
    })
    let sasMode = false
    Qs("#pop_up_detail","click",(e,ev)=>{
        if (ev.target === e) {
            if (!sasMode) {
                bodyRemove(modal);
            }
        }
    })
    Qs(".bx-x","click",(e,ev)=>{
        if (ev.target === e) {
            if (!sasMode) {
                bodyRemove(modal);
            }
        }
    })
    Qst('#pop_up_detail [alert-content="ended2"]',"click",(e,ev)=>{
        if (onclose !== null ) {
            onclose (e,ev);
        }
        let fath = e.thisParent()
        fath.hideThis()
        sasMode=true
        if (e.getData("action") !== "contin") {
            JSSend(
                'POST',
                "https://app.depotbetpro.com/backend/updater.php",
                {coupon:data.coupon,ID:u_id,h_up:true,event:data.event,new_status:e.getData("action"),h_token:data.token},
                (result,response)=>{
                    if (result) {
                        // console.log(response)
                        if (response.status) {
                            if (e.getData("action") === "validé") {
                                bodyRemove(modal)
                                tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>validation",
                                    `<div class='row centry'>
                                        <i class='bx bxs-check tl_fs-22' style='color: var(--app-secondary-color)'></i>
                                    </div> 
                                    opération validée avec succès!
                                    `
                                )
                            }
                            else {
                                bodyRemove(modal)
                                tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>validation",
                                    `<div class='row centry'>
                                        <i class='bx bxs-check tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                    </div> 
                                    opération rejetée avec succès!
                                    `
                                )
                            }
                            if (onValidate !== null ) {
                                onValidate();
                            }
                            
                        }
                        else {
                            fath.showThis("flex")
                            sasMode=false
                            tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                                `<div class='row centry'>
                                    <i class='bx bx-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                </div> 
                                Oups! Une erreur s'est produite,réessayer plus tard`
                            )
                        }
                        if (onclose !== null ) {
                            onclose (e,ev);
                        }
                    }
                    else {
                        bodyRemove(modal)
                        tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                    }
                }
            )
        }
        else {
            // console.log('sh re')
            // <TLRecharge cp={data.coupon} token={data.token} modal={modal} />
            window.location.href = tlGET.origin+"/App/recharge/"+data.coupon.toLowerCase()+"/_?tk="+data.token
        }
        
    })
    Qst('#pop_up_detail [alert-content="d"]').setAttribute('alert-set',"")
}

export function pop_up_notif_detail (data,title="Notification ",onclose=null) {
    if (title === "") {
        title="Notification"
    }
    // let event = title.split(" ")[2]
    let modal = createdElement("div",{addid:"pop_up_detail",})
    let content = (data.content.replace(/\[copy\]/g,'<span class="tl-copy">')).replace(/\[\/copy\]/g,'</span>')
    // console.log(content)
    let addhtml= `
        <div alert-content="logo">
            
        </div>
        <div alert-content="d">
            <i class="bx bx-x tl_fs-20 transition-all-ease-3 tl_cursor-pointer tl_color-red-hover" style="position:absolute;top:-40px;right:10px;"></i>
            <h2 alert-content="title">
            <i class="bx bxs-bell tl_mr-15 tl_fs-20" style="color:var(--ux-grey)"></i>${data.title}
            </h2>
            <div alert-content="content" style="font-size: 13px;display:inline-block">
                ${content}
            </div>
        </div>
        `
    modal.htmlThis(addhtml)
    bodyAppend(modal)
    Qs('.tl-copy','contextmenu',async (e,ev)=>{
        // console.log(e)
        let myToast = new Toast()
        myToast.init()
        if (await copyAdress(e.innerText)) {
            myToast.show("<i class='bx bx-check-circle'></i> text copié !",3500,true)
        }
    })
    Qs("#pop_up_detail","click",(e,ev)=>{
        if (ev.target === e) {
            bodyRemove(modal);
        }
    })
    Qs(".bx-x","click",(e,ev)=>{
        if (ev.target === e) {
            bodyRemove(modal);
        }
    })
    Qst('#pop_up_detail [alert-content="d"]').setAttribute('alert-set',"")
}



// export function bioAuthentificator  () {
//     return new Promise (async (resolve)=>{
//         try {
//             let availability = await BiometricAuth.isAvailable()
//             if (availability.has) {
//                 const resulted = await BiometricAuth.verify({
//                     reason: "Processus d'authentification",
//                     title: 'Authentification biométrique',
//                     subtitle: "Utilisez ton authentification biométrique pour vérifier que c'est bien vous",
//                     description: 'Placez votre doigt sur le capteur',
//                 })
//                 if(resulted.verified) {
//                     console.log('Authentication successful!');
//                     resolve({status:true,isAvailable:true})
//                 } else {
//                     console.log('Authentication failed');
//                     resolve({status:false,isAvailable:true})
//                 }
//             }
//             else {
//                 resolve({status:false,isAvailable:false})
//             }
//         } catch (error) {
//           console.error('Authentication error:', error);
//           resolve({status:false,isAvailable:false})
//         }
//     })
    
//   };
if (!localStorage.getItem('sessionId') && localStorage.getItem('sessionId') === null ) {
    localStorage.setItem('sessionId', generateUUID());  // Générez et stockez un UUID
}
// uri = "https://api.tlab-webdev.com/api/webpush/notifier.php"

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

async function mainPush (route="./") {
    const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/dbpsw.js`)
    const subscription = await registration.pushManager.getSubscription()

    // console.log(subscription)
    if (!'Notifications' in window && !'serviceWorker' in navigator) {
        tl_alert("","not have notification slot")
        return;
    }
    if (subscription === null) {
        askPermission()
    }
    else {
        let sessId = localStorage.getItem('userData') && localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== undefined ? JSON.parse(localStorage.getItem('userData')).id : ""
        if (subscription !== null && subscription !== undefined ){
            setSubscriptionCookie(subscription);
        }
        JSSend(
            'POST',
            'https://api.tlab-webdev.com/api/webpush/getSubscrib.php',
            {
                sessionId: sessId, // Supposons que vous ayez un ID de session stocké dans localStorage
                subscription: JSON.stringify(subscription)
            }, 
            (success,response)=>{
                // console.log(response)
                if (success) {
                    if (!response.status){
                        if (subscription !== null && subscription !== undefined ){
                            // setSubscriptionCookie(subscription);
                            
                        }
                        return
                    }
                    // console.log(response)
                }
            }
        )
    }
}

async function askPermission (e) {
    let permission = await Notification.requestPermission()
    if (permission === "granted") {
        registerServiceWorker()
    }

}

async function registerServiceWorker () {
    navigator.serviceWorker.ready.then((registration)=>{
        registration.pushManager.subscribe({
            userVisibleOnly:true,
            applicationServerKey: "BEbeDIcES1K1s09yodAnNVJT98w6J8Dmos21PJ50v3Hmb3kv6R-AEIOa8tA5jn9nzOpUL8irzzxLcKe3uOnJ5h0",

        }).then(sub=>{
            // console.log(JSON.stringify(sub));
            setSubscriptionCookie(sub);
        });
    });
}

function setSubscriptionCookie(subscription) {
    let sessId = localStorage.getItem('userData') && localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== undefined ? JSON.parse(localStorage.getItem('userData')).id : ""
    let sub = typeof subscription !== "string" ? JSON.stringify(subscription) : subscription 
    JSSend(
        'POST',
        'https://api.tlab-webdev.com/api/webpush/setSubscrib.php',
        {
            sessionId: sessId, // Supposons que vous ayez un ID de session stocké dans localStorage
            subscription: sub
        }, 
        (success,response)=>{
            if (success) {
                // tl_alert("",response)
            }
        }
    )
    
}
export function setSubscription (subscription) {
    let sessId = localStorage.getItem('userData') && localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== undefined ? JSON.parse(localStorage.getItem('userData')).id : ""
    let sub = typeof subscription !== "string" ? JSON.stringify(subscription) : subscription 
    JSSend(
        'POST',
        'https://api.tlab-webdev.com/api/webpush/setSubscrib.php',
        {
            sessionId: sessId, // Supposons que vous ayez un ID de session stocké dans localStorage
            subscription: sub
        }, 
        (success,response)=>{
            if (success) {
                // tl_alert("",response)
            }
        }
    )
    
}
let permComt = 0
export function askPerm (path="./") {
    path = path===""?"./":path
    if (permComt === 0) {
        setTimeout(() => {
            if (getFirstplatform === 'android') {
                // addListeners()
                setTimeout(() => {
                    askPush()
                }, 500);
            } 
            mainPush(path)
        }, 500);
    }
    permComt++
}
HTMLElement.prototype.setAttr = HTMLElement.prototype.setAttribute
HTMLElement.prototype.getAttr = HTMLElement.prototype.getAttribute
HTMLElement.prototype.removeAttr = HTMLElement.prototype.removeAttribute
let myStyle = document.createElement("style")
Qst("head").appendChild(myStyle)
let allEl = Qst("**")
/**
* NominateDate the day and the month like this (mon.|tue.|wed.... && jan.|feb.|mar....)
* @param {*} date the date to nominate at this format yyyy/mm/dd
* @returns {{d:string,m:string}} 
*/
export function nominateDate (date) {
    let wday = ["Dim.","Lun.","Mar.","Mer.","Jeu.","Ven.","Sam."]
    let ymon = ["Jan.","Fev.","Mar.","Avr.","Mai","Jun.","Juil.","Août.","Sep.","Oct.","Nov.","Dec."]
    let decompoz = date.split("/")
    let yY = parseInt(decompoz[0])
    let mM = parseInt(decompoz[1]-1)
    let dD = parseInt(decompoz[2])
    let ddm = new Date(yY,mM,dD)
    
    let xmD = ddm.getDay()
    let xmM = ddm.getMonth()
    
    return {d:wday[xmD],m:ymon[xmM]}
}
class DatePicked {
    value
}
export let myDat = new DatePicked()

if (allEl && allEl.length > 0) {
    // let styleContent = ``
    allEl.forEach((al,indx) => {
        let stimed = "tl_scs_commit_"+(new Date()).getTime().toString().slice(4)+"_"+indx
        if (al.hasAttribute("tl-mode")) {
            al.removeAttr("tl-mode")
            myStyle.innerText += `.${stimed} {`
            if (al.hasAttribute("background") || al.hasAttribute("bg")) {
                let cncer = al.getAttr('background') || al.getAttr('bg')
                al.removeAttr("bg")
                al.removeAttr("background")
                myStyle.innerText += `background:${cncer};`
            }
            if (al.hasAttribute("text-align")) {
                let cncer = al.getAttr('text-align')
                al.removeAttr("text-align")
                myStyle.innerText += `text-align:${cncer};`
            }
            if (al.hasAttribute("pointer-events")) {
                let cncer = al.getAttr('pointer-events')
                al.removeAttr("pointer-events")
                myStyle.innerText += `pointer-events:${cncer};`
            }
            if (al.hasAttribute("center")) {
                al.removeAttr("center")
                myStyle.innerText += `display:flex;align-items:center;justify-content:center;`
            }
            if (al.hasAttribute("col")) {
                al.removeAttr("col")
                myStyle.innerText += `display:flex;width:100%;flex-direction:column;`
            }
            if (al.hasAttribute("row")) {
                al.removeAttr("row")
                myStyle.innerText += `display:flex;width:100%;flex-direction:row;`
            }
            if (al.hasAttribute("display") || al.hasAttribute("d")) {
                let cncer = al.getAttr('display') || al.getAttr('d')
                al.removeAttr("display")
                al.removeAttr("d")
                myStyle.innerText += `display:${cncer};`
            }
            if (al.hasAttribute("flex")) {
                al.removeAttr("flex")
                myStyle.innerText += `display:flex;`
            }
            if (al.hasAttribute("gap")) {
                let cncer = al.getAttr('gap')
                al.removeAttr("gap")
                myStyle.innerText += `gap:${cncer};`
            }
            if (al.hasAttribute("circle")) {
                al.removeAttr("circle")
                myStyle.innerText += `border-radius:50%;`
            }
            if (al.hasAttribute("overflow")) {
                let cncer = al.getAttr('overflow')
                al.removeAttr("overflow")
                myStyle.innerText += `overflow:${cncer};`
            }
            if (al.hasAttribute("overflow-x")) {
                let cncer = al.getAttr('overflow-x')
                al.removeAttr("overflow-x")
                myStyle.innerText += `overflow-x:${cncer};`
            }
            if (al.hasAttribute("overflow-y")) {
                let cncer = al.getAttr('overflow-y')
                al.removeAttr("overflow-y")
                myStyle.innerText += `overflow-y:${cncer};`
            }
            if (al.hasAttribute("ai")) {
                let cncer = al.getAttr('ai')
                al.removeAttr("ai")
                myStyle.innerText += `align-items:${cncer};`
            }
            if (al.hasAttribute("jc")) {
                let cncer = al.getAttr('jc')
                al.removeAttr("jc")
                myStyle.innerText += `justify-content:${cncer};`
            }
            if (al.hasAttribute("flex")) {
                let cncer = al.getAttr('flex')
                al.removeAttr("flex")
                myStyle.innerText += `flex-direction:${cncer};`
            }
            if (al.hasAttribute("animation")) {
                let cncer = al.getAttr('animation')
                al.removeAttr("animation")
                myStyle.innerText += `animation:${cncer};`
            }
            if (al.hasAttribute("animation-d")) {
                let cncer = al.getAttr('animation-d')
                al.removeAttr("animation-d")
                myStyle.innerText += `animation-delay:${cncer};`
            }
            if (al.hasAttribute("transition")) {
                let cncer = al.getAttr('transition')
                al.removeAttr("transition")
                myStyle.innerText += `transition:${cncer};`
            }
            if (al.hasAttribute("transition-d")) {
                let cncer = al.getAttr('transition-d')
                al.removeAttr("transition-d")
                myStyle.innerText += `transition-delay:${cncer};`
            }
            if (al.hasAttribute("td")) {
                let cncer = al.getAttr('td')
                al.removeAttr("td")
                myStyle.innerText += `transition-delay:${cncer};`
            }
            if (al.hasAttribute("t")) {
                let cncer = al.getAttr('t')
                al.removeAttr("t")
                myStyle.innerText += `transition:${cncer};`
            }
            if (al.hasAttribute("shadow")) {
                let cncer = al.getAttr('shadow')
                al.removeAttr("shadow")
                myStyle.innerText += `box-shadow:${cncer};`
            }
            if (al.hasAttribute("family")) {
                let cncer = al.getAttr('family')
                al.removeAttr("family")
                myStyle.innerText += `font-family:${cncer};`
            }
            if (al.hasAttribute("opacity")) {
                let cncer = al.getAttr('opacity')
                al.removeAttr("opacity")
                myStyle.innerText += `opacity:${cncer};`
            }
            if (al.hasAttribute("fs")) {
                let cncer = al.getAttr('fs')
                al.removeAttr("fs")
                myStyle.innerText += `font-size:${cncer};`
            }
            if (al.hasAttribute("decoration")) {
                let cncer = al.getAttr('decoration')
                al.removeAttr("decoration")
                myStyle.innerText += `text-decoration:${cncer};`
            }
            if (al.hasAttribute("text-transform")) {
                let cncer = al.getAttr('text-transform')
                al.removeAttr("text-transform")
                myStyle.innerText += `text-transform:${cncer};`
            }
            if (al.hasAttribute("fw")) {
                let cncer = al.getAttr('fw')
                al.removeAttr("fw")
                myStyle.innerText += `font-weight:${cncer};`
            }
            if (al.hasAttribute("top")) {
                let cncer = al.getAttr('top')
                al.removeAttr("top")
                myStyle.innerText += `top:${cncer};`
            }
            if (al.hasAttribute("left")) {
                let cncer = al.getAttr('left')
                al.removeAttr("left")
                myStyle.innerText += `left:${cncer};`
            }
            if (al.hasAttribute("right")) {
                let cncer = al.getAttr('right')
                al.removeAttr("right")
                myStyle.innerText += `right:${cncer};`
            }
            if (al.hasAttribute("bottom")) {
                let cncer = al.getAttr('bottom')
                al.removeAttr("bottom")
                myStyle.innerText += `bottom:${cncer};`
            }
            if (al.hasAttribute("color")) {
                let cncer = al.getAttr('color')
                al.removeAttr("color")
                myStyle.innerText += `color:${cncer};`
            }
            if (al.hasAttribute("position")) {
                let cncer = al.getAttr('position')
                al.removeAttr("position")
                myStyle.innerText += `position:${cncer};`
            }
            if (al.hasAttribute("border")) {
                let cncer = al.getAttr('border')
                al.removeAttr("border")
                myStyle.innerText += `border:${cncer};`
            }
            if (al.hasAttribute("border-radius")) {
                let cncer = al.getAttr('border-radius')
                al.removeAttr("border-radius")
                myStyle.innerText += `border-radius:${cncer};`
            }
            if (al.hasAttribute("padding")) {
                let cncer = al.getAttr('padding')
                al.removeAttr("padding")
                myStyle.innerText += `padding:${cncer};`
            }
            if (al.hasAttribute("padding-top") || al.hasAttribute("pt")) {
                let cncer = al.getAttr('margin-top') || al.getAttr('pt')
                al.removeAttr("padding-top")
                al.removeAttr("pt")
                myStyle.innerText += `padding-top:${cncer};`
            }
            if (al.hasAttribute("padding-left") || al.hasAttribute("pl")) {
                let cncer = al.getAttr('padding-left') || al.getAttr('pl')
                al.removeAttr("padding-left")
                al.removeAttr("pl")
                myStyle.innerText += `padding-left:${cncer};`
            }
            if (al.hasAttribute("padding-bottom") || al.hasAttribute("pb")) {
                let cncer = al.getAttr('padding-bottom') || al.getAttr('pb')
                al.removeAttr("padding-bottom")
                al.removeAttr("pb")
                myStyle.innerText += `padding-bottom:${cncer};`
            }
            if (al.hasAttribute("padding-right") || al.hasAttribute("pr")) {
                let cncer = al.getAttr('padding-right') || al.getAttr('pr')
                al.removeAttr("padding-right")
                al.removeAttr("pr")
                myStyle.innerText += `padding-right:${cncer};`
            }
            if (al.hasAttribute("margin")) {
                let cncer = al.getAttr('margin')
                al.removeAttr("margin")
                myStyle.innerText += `margin:${cncer};`
            }
            if (al.hasAttribute("margin-top") || al.hasAttribute("mt")) {
                let cncer = al.getAttr('margin-top') || al.getAttr('mt')
                al.removeAttr("margin-top")
                al.removeAttr("mt")
                myStyle.innerText += `margin-top:${cncer};`
            }
            if (al.hasAttribute("margin-left") || al.hasAttribute("ml")) {
                let cncer = al.getAttr('margin-left') || al.getAttr('ml')
                al.removeAttr("margin-left")
                al.removeAttr("ml")
                myStyle.innerText += `margin-left:${cncer};`
            }
            if (al.hasAttribute("margin-bottom") || al.hasAttribute("mb")) {
                let cncer = al.getAttr('margin-bottom') || al.getAttr('mb')
                al.removeAttr("margin-bottom")
                al.removeAttr("mb")
                myStyle.innerText += `margin-bottom:${cncer};`
            }
            if (al.hasAttribute("margin-right") || al.hasAttribute("mr")) {
                let cncer = al.getAttr('margin-right') || al.getAttr('mr')
                al.removeAttr("margin-right")
                al.removeAttr("mr")
                myStyle.innerText += `margin-right:${cncer};`
            }
            if (al.hasAttribute("ph") || al.hasAttribute("padding-horizontal")) {
                let cncer = al.getAttr('ph') || al.getAttr('padding-horizontal')
                al.removeAttr("padding-horizontal")
                al.removeAttr("ph")
                myStyle.innerText += `padding-left:${cncer};padding-right:${cncer};`
            }
            if (al.hasAttribute("mh") || al.hasAttribute("margin-horizontal")) {
                let cncer = al.getAttr('mh') || al.getAttr('margin-horizontal')
                al.removeAttr("margin-horizontal")
                al.removeAttr("mh")
                myStyle.innerText += `margin-left:${cncer};margin-right:${cncer};`
            }
            if (al.hasAttribute("outline")) {
                let cncer = al.getAttr('outline')
                al.removeAttr("outline")
                myStyle.innerText += `outline:${cncer};`
            }
            if (al.hasAttribute("width") || al.hasAttribute("w")) {
                let cncer = al.getAttr('width') || al.getAttr('w')
                al.removeAttr("width")
                al.removeAttr("w")
                myStyle.innerText += `width:${cncer};`
            }
            if (al.hasAttribute("min-width") || al.hasAttribute("min-w")) {
                let cncer = al.getAttr('min-width') || al.getAttr('min-w')
                al.removeAttr("min-width")
                al.removeAttr("min-w")
                myStyle.innerText += `min-width:${cncer};`
            }
            if (al.hasAttribute("max-width") || al.hasAttribute("max-w")) {
                let cncer = al.getAttr('max-width') || al.getAttr('max-w')
                al.removeAttr("max-width")
                al.removeAttr("max-w")
                myStyle.innerText += `max-width:${cncer};`
            }
            if (al.hasAttribute("height") || al.hasAttribute("h")) {
                let cncer = al.getAttr('height') || al.getAttr('h')
                al.removeAttr("height")
                al.removeAttr("h")
                myStyle.innerText += `height:${cncer};`
            }
            if (al.hasAttribute("min-height") || al.hasAttribute("min-h")) {
                let cncer = al.getAttr('min-height') || al.getAttr('min-h')
                al.removeAttr("min-height")
                al.removeAttr("min-h")
                myStyle.innerText += `min-height:${cncer};`
            }
            if (al.hasAttribute("max-height") || al.hasAttribute("max-h")) {
                let cncer = al.getAttr('max-height') || al.getAttr('max-h')
                al.removeAttr("max-height")
                al.removeAttr("max-h")
                myStyle.innerText += `max-height:${cncer};`
            }
            if (al.hasAttribute("transform") || al.hasAttribute("tf")) {
                let cncer = al.getAttr('transform') || al.getAttr('tf')
                al.removeAttr("transform")
                al.removeAttr("tf")
                myStyle.innerText += `transform:${cncer};`
            }
            if (al.hasAttribute("cursor")) {
                let cncer = al.getAttr('cursor')
                al.removeAttr("cursor")
                myStyle.innerText += `cursor:${cncer};`
            }
            myStyle.innerText += `}`
            if (al.hasAttribute("onhover")) {
                let cncer = al.getAttr('onhover')
                if (!cncer.endsWith(';')) cncer += ";"
                al.removeAttr("onhover")
                myStyle.innerText += `.${stimed}:hover {${cncer.replace(/\$/g, ":")}}`
            }
            al.addClass(stimed)
        }
    })
}