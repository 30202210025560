// external: https://api.tlab-webdev.com-/
// external: https://app.depotbetpro.com-/
// local: https://api.depotbetpro.io-/

import React,{ useEffect,useRef,useState } from "react";
// import { loading } from "../function.js";
import { TextField,NumField,SubmitButton, Icon } from "../widgets/widgets.js";
import { Row,Col } from "../widgets/layout_orient";
import { Link, useNavigate } from "react-router-dom";
import { Qs,Qst,tl_alert,isValidMail,thisFormData,mapToObject, JSSend, factoriString } from "../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { useUserData } from "../userContext";
import { Text,Title,TextAction } from "../widgets/widgets.js";
import AskAge from "./askAge.js";

import "./auth.css";
import { loading, tl_confirme } from "../function.js";
const numOptions = [
    { name: "Bénin", code: "BJ", value: "+229", src:process.env.PUBLIC_URL+"/images/flag-bj.png" },
    // { name: "Burkina Faso", code: "BF", value: "+226",src:process.env.PUBLIC_URL+"/images/flag-bf.png"  },
    // { name: "Côte d'Ivoire", code: "CI", value: "+225",src:process.env.PUBLIC_URL+"/images/flag-ci.png"  },
    // { name: "Gabon", code: "GA", value: "+241",src:process.env.PUBLIC_URL+"/images/flag-ga.png"  },
    // { name: "Niger", code: "NE", value: "+227", src:process.env.PUBLIC_URL+"/images/flag-ne.png" },
    // { name: "Togo", code: "TG", value: "+228",src:process.env.PUBLIC_URL+"/images/flag-tg.png" }
];

let tFrm
let tl_condtu = false;
let tl_gapacomp = 0
function Auth () {

    const nav = useNavigate()
    
    // let [userData,setUserData] = useState({});
    const [userData, setUserData] = useUserData();

    let [cond,setCond] = useState(false);

    let [validName,setValidName] = useState(true);
    let [validMail,setValidMail] = useState(true);
    let [validPass,setValidPass] = useState(true);
    let [validNum,setValidNum] = useState(true);

    let [validLogPass,setLogValidPass] = useState(true);
    let [validLogNum,setLogValidNum] = useState(true);

    let [realValidName,setRealValidName] = useState(false);
    let [realValidMail,setRealValidMail] = useState(false);
    let [realValidPass,setRealValidPass] = useState(false);
    let [realValidNum,setRealValidNum] = useState(false);

    let [realLogValidPass,setRealLogValidPass] = useState(false);
    let [realLogValidNum,setRealLogValidNum] = useState(false);

    let [readyC,setReadyC] = useState(false);

    function isValidName (e) {
        if (e.value.length < 2 && e.value.length > 0 ) {
            setValidName(false)
        }
        else {
            setValidName(true)
            if (e.value.length < 2 ) {
                setRealValidName(false)
            }
            else {
                setRealValidName(true)
            }
        }
    }
    function isValidLogNum (ehid,e) {
        var input = e.value.replace(/\D/g, '');  // Supprime tout ce qui n'est pas un chiffre
        // Utilise regex pour formater par groupes de deux chiffres
        var formatted = input.slice(0,8).replace(/(\d{2})(?=\d)/g, '$1 ');
        e.value = formatted;
        if (ehid.value.length < 13 && ehid.value.length > 5 ) {
            setLogValidNum(false)
        }
        else {
            setLogValidNum(true)
            if (ehid.value.length < 13 ) {
                setRealLogValidNum(false)
            }
            else {
                setRealLogValidNum(true)
            }
        }
    }
    function isValidNum (ehid,e) {

        var input = e.value.replace(/\D/g, '');  // Supprime tout ce qui n'est pas un chiffre
        // Utilise regex pour formater par groupes de deux chiffres
        var formatted = input.slice(0,8).replace(/(\d{2})(?=\d)/g, '$1 ');
        e.value = formatted;
        if (ehid.value.length < 13 && ehid.value.length > 5 ) {
            setValidNum(false)
        }
        
        else {
            setValidNum(true)
            if (ehid.value.length < 13 ) {
                setRealValidNum(false)
            }
            else {
                setRealValidNum(true)
            }
        }
        // setNum(e.value.slice(0,8).replace(/(\d{2})(?=\d)/g, '$1 '))
    }
    function isValidLogPassword (e) {
        if (e.value.length >= 8) {
            setLogValidPass(true)
            setRealLogValidPass(true)
        }
        else {
            setLogValidPass(false)
            setRealLogValidPass(false)
        }
    }
    function isValidPassword (e) {
        if (e.value.length >= 8) {
            setValidPass(true)
            setRealValidPass(true)
        }
        else {
            setValidPass(false)
            setRealValidPass(false)
        }
    }
    
    function whenBlured (e) {
        if (e.value <= 0) {
            setValidMail(true)
            setValidPass(true)
            setValidNum(true)
            setValidName(true)

            setLogValidNum(true)
            setLogValidPass(true)
        }
    }
    function mailCheck (e) {
        var input = e.value.toLowerCase();
        if (!isValidMail(input) && input.length > 0) {
            setValidMail(false)
        }
        else {
            setValidMail(true)
            if (isValidMail(input) ) {
                setRealValidMail(true)
            }
            else {
                setRealValidMail(false)
            }
        }
        e.value = input;
    }
    // function resetAll () {
    //     setRealLogValidNum(false)
    //     setRealLogValidPass(false)
    //     setRealValidMail(false)
    //     setRealValidName(false)
    //     setRealValidPass(false)
    //     setRealValidNum(false)

    //     setValidMail(true)
    //     setValidName(true)
    // }
    function authTo (type) {
        // await futureBuilder(500)
        if (type === "Auth1") {
            Qs("form-slot").setAttribute("auth","1")
        }
        else {
            Qs("form-slot").setAttribute("auth","0")
        }
    }
    function changeThem (e) {
      
        let elem = e.target
        if (elem.containsClass("bx-moon")) {
            elem.replaceClass("bx-moon tl_color-white","bxs-moon")
            Qst('html').setAttribute("lmd","true")
            Qs("imgReg").src = "../images/logo_anim_dark.gif"
            localStorage.setItem("them","light")
        }
        else {
            elem.replaceClass("bxs-moon","bx-moon tl_color-white")
            Qst('html').removeAttribute("lmd")
            Qs("imgReg").src = "../images/logo_anim.gif"
            localStorage.setItem("them","dark")
        }
  
     }
     const useEffectExecuted = useRef(false); // Utilisation d'une référence
    useEffect (()=>{
        if (!useEffectExecuted.current && tl_gapacomp === 0) {
            tl_gapacomp ++
            if ( userData !== null && userData !== undefined &&  userData.hasOwnProperty("reg_urol") &&  userData.hasOwnProperty("reg_uname") && userData.hasOwnProperty("reg_utel") && userData.hasOwnProperty("reg_umail") ) {
                // await futureBuilder (3000)
                Qs('log_unum').value = userData.reg_utel.split(" ")[1]
                authTo ("Auth1")
                if (userData.reg_urol === "admin") {
                    nav("/Admin",{replace:true})
                    return
                }
                else {
                    nav("/home",{replace:true})
                    return
                }
            
            }
            else {
                setReadyC(true)
                // AllRead = true
                if (localStorage.getItem("them") !== null && localStorage.getItem("them") !== undefined && localStorage.getItem("them") === "light") {
                    Qs('themer').replaceClass("bx-moon tl_color-white","bxs-moon")
                    Qst('html').setAttribute("lmd","true")
                    setTimeout(() => {
                    Qs("imgReg").src = "../images/logo_anim_dark.gif"
                    }, 500);
                }
                else {
                    Qs('themer').replaceClass("bxs-moon","bx-moon tl_color-white")
                    Qst('html').removeAttribute("lmd")
                    setTimeout(() => {
                        Qs("imgReg").src = "../images/logo_anim.gif"
                    }, 500);
                }
               
            }
            Qst("global-loader").removeAttribute("inLoad")
        }
    },[nav,userData])
    function submitting (e) {
        e.preventDefault()
        let theForm = e.target.thisParent()
        if (!theForm.hasAttribute("inauth")) {
            if ( e.target.getAttribute("sub-type")==="log" ) {
                if (realLogValidNum && realLogValidPass) {
                    theForm.setAttribute ("inauth","true")
                    // tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>login",
                    //     `<div class='row centry'>
                    //         <i class='bx bx-check-shield tl_fs-22' style='color: var(--app-secondary-color)'></i>
                    //     </div> 
                    //     Connexion réussie, Heureux de vous revoir 🥰!`
                    // )
                    let thisData = new Map(new thisFormData(theForm).entries())
                    let userDt = mapToObject(thisData)
                    
                    // setUserData(userDt)
                    // console.log(userDt)
                    Qst('process-loader').setAttribute('inLoad',true)
                    JSSend(
                        'POST',
                        "https://app.depotbetpro.com/backend/Auth.php",
                        factoriString(userDt),
                        async (result,response)=>{
                            if (result) {
                                // console.log(response)
                                Qst('process-loader').removeAttribute('inLoad')
                                if (typeof response === "string") {
                                    theForm.removeAttribute ("inauth")
                                }
                                if (response.status) {
                                    setTimeout(() => {
                                        theForm.removeAttribute ("inauth")
                                        tl_gapacomp = 0
                                        tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>Connexion",
                                            `<div class='row centry'>
                                                <i class='bx bx-check-shield tl_fs-22' style='color: var(--app-secondary-color)'></i>
                                            </div> 
                                            Connexion réussie, Heureux de vous revoir 🥰!`,
                                            async () => {
                                                await loading (3000,()=>{},()=>{
                                                    setUserData(response.result)
                                                    if (response.result.reg_urol === "admin") {
                                                        nav("/Admin",{replace:true});
                                                        return
                                                    }
                                                    else {
                                                        nav("/home",{replace:true});
                                                        return
                                                    }
                                                })
                                                
                                            }
                                        )
                                        
                                    }, 500);
                                }
                                else if (response.result === "Av0") {
                                    theForm.removeAttribute ("inauth")
                                    tl_alert('Authentification',"Utilisateur inexistant vérifier votre numéro et réessayé")
                                }
                                else if (response.result === "Pass0") {
                                    theForm.removeAttribute ("inauth")
                                    tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                                        `<div class='row centry'>
                                            <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                        </div> 
                                        Mot de passe incorrect!`
                                    )
                                }
                            }
                            else {
                                Qst('process-loader').removeAttribute('inLoad')
                                theForm.removeAttribute ("inauth")
                                tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                            }
                        }
                    )
                    
                }
                else {
                    
                    if (!realLogValidNum) {
                        setLogValidNum(false)
                    }
                    if (!realLogValidPass) {
                        setLogValidPass(false)
                    }
                    tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                        `<div class='row centry'>
                            <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                        </div> 
                        Assurez-vous de bien remplir les champs comme il se doit!`
                    )
                }
            }
            else if ( e.target.getAttribute("sub-type")==="reg" ) {
                if (realValidMail && realValidName && realValidNum && realValidPass) {
                    theForm.setAttribute ("inauth","true")
                    let thisData = new Map(new thisFormData(theForm).entries())
                    let userDt = mapToObject(thisData)
                    
                    tFrm = userDt
                    Qst('process-loader').setAttribute('inLoad',true)
                    JSSend(
                        'POST',
                        "https://app.depotbetpro.com/backend/Auth.php",
                        factoriString(tFrm),
                        async (result,response)=>{
                            if (result) {
                                // console.log(response)
                                if (typeof response === "string") {
                                    theForm.removeAttribute ("inauth")
                                }
                                if (response.status) {
                                    setTimeout(() => {
                                        Qst('process-loader').removeAttribute('inLoad')
                                        theForm.removeAttribute ("inauth")
                                        Qs('rowWrap').setAttribute("askage","true")
                                        // console.log("checked: true")
                                    }, 2000);
                                }
                                else if (response.result === "Er£!0x") {
                                    Qst('process-loader').removeAttribute('inLoad')
                                    theForm.removeAttribute ("inauth")
                                    tl_alert('Erreur utilisateur',"Utilisateur existant vérifier votre numéro ou votre adresse email et réessayé")
                                }
                                else if (response.result === "MailError") {
                                    Qst('process-loader').removeAttribute('inLoad')
                                    theForm.removeAttribute ("inauth")
                                    tl_alert('Erreur Email',"Adresse email invalide!")
                                }
                            }
                            else {
                                Qst('process-loader').removeAttribute('inLoad')
                                theForm.removeAttribute ("inauth")
                                tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                            }
                        }
                    )
                    return
                }
                else {
                    if (!realValidMail) {
                        setValidMail(false)
                    }
                    if (!realValidName) {
                        setValidName(false)
                    }
                    if (!realValidNum) {
                        setValidNum(false)
                    }
                    if (!realValidPass) {
                        setValidPass(false)
                    }
                    tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                        `<div class='row centry'>
                            <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                        </div> 
                        Assurez-vous de bien remplir les champs comme il se doit!`
                    )
                }
            }
        }
        
    }
    async function terminate (e,status,value) {
        e.target.thisParent().setAttribute ("inauth","true")
        let fther = e.target.thisParent()
        if (status) {
            tFrm["reg_ubirth"] = value
            tFrm["u_reg"] = "u_reg"
            Qst('process-loader').setAttribute('inLoad',true)
            
            JSSend(
                'POST',
                "https://app.depotbetpro.com/backend/Auth.php",
                factoriString(tFrm),
                (result,response)=>{
                    if (result) {
                        if (typeof response === "string") {
                            fther.removeAttribute ("inauth")
                        }
                        Qst('process-loader').removeAttribute('inLoad')
                        if (response.status) {
                             tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>Enregistrement",
                                `<div class='row centry'>
                                    <i class='bx bxs-check-shield tl_fs-22' style='color: var(--app-secondary-color)'></i>
                                </div> 
                                enregistrement réussi, Bienvenue 😊!`
                            ,async ()=>{
                                await loading (3000,()=>{},()=>{
                                    setUserData(response.result)
                                    tl_gapacomp = 0
                                    if (response.result.reg_urol === "admin") {
                                        nav("/Admin",{replace: true})
                                        return
                                    }
                                    else {
                                        nav("/home",{replace: true})
                                    }
                                })
                            })
                            
                        }
                        else if (response.result === "Er£!0x") {
                            fther.removeAttribute ("inauth")
                            tl_alert('Erreur',"Utilisateur existant vérifier votre numéro et réessayé")
                        }
                    }
                    else {
                        fther.removeAttribute ("inauth")
                        tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                    }
                }
            )
        } 
        else {
            tl_alert("infos","Vous n'avez pas encore 18 ans")
        }
    }
    async function forgetPass (e) {
        let ft = e.target.thisParent(2)
        let dt = mapToObject(new Map(new thisFormData(ft)))
        dt["fg_pass"] = "true"
        dt["u_tel"] = dt.log_utel
        if (!await tl_confirme("Mot de passe oublié ?","En confirmant votre mot de passe sera réinitialisé et vous sera envoyé dans votre boît email.")) {
            return
        }
        if (dt.log_utel.length < 13) {
            tl_alert("","veuillez renseigner votre numéro d'abord, au bon format")
            return
        }
        Qst('process-loader').setAttribute('inLoad',true)
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/updater.php",
            dt,
            (result,response)=>{
                // console.log(response)
                if (result) {
                    Qst('process-loader').removeAttribute('inLoad')
                    if (response.status) {
                        tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>mise à jour",
                            `<div class='row centry'>
                                <i class='bx bxs-check-circle tl_fs-22' style='color: var(--app-secondary-color)'></i>
                            </div> 
                            votre mot de passe a bien été mise à jour et envoyé dans votre boîte email`
                        )
                    }
                    else {
                        // tl_alert("",response.result)
                        if (response.result === "U0") {
                            tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>Erreur",
                                `<div class='row centry'>
                                    <i class='bx bxs-x-circle tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                </div> 
                                le numéro n'existe pas`
                            )
                        }
                        else {
                            tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                                `<div class='row centry'>
                                    <i class='bx bx-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                </div> 
                                Oups! Une erreur s'est produite lors du changement de mot de passe`
                            )
                        }
                        
                    }
                }
                else {
                    tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                }
            }
        )
        // console.log(ft)
        

        // tl_alert("","Votre nouveau mot de passe vous à été envoyer par email, veuillez l'utiliser")
    }
    function changeCond(e) {
        setCond(!tl_condtu)
        tl_condtu = !tl_condtu;
        if (tl_condtu) {
            Qs("ckecer").replaceClass('bx-circle',"bxs-check-circle")
        }
        else {
            Qs("ckecer").replaceClass("bxs-check-circle",'bx-circle')
        }
    }
    function onRetur () {
        Qs('rowWrap').setAttribute("askage","false")
    }
    return (
        <section className='auth tl_flex-column centry tl_mb-20 tl_position-relative'>
            <Icon icon="bx-moon" onClick={changeThem} className="themer tl_position-fixed tl_z-999 tl_top-20px tl_right-30px tl_cursor-pointer tl_p-5 tl_fs-20"/>
            {/* <h4 className="tl_color-white">Auth form</h4> */}
            <Row className="authForm-wrapper rowWrap">
                <div className="form-slot" auth='1'>
                    <Row className="formThumb tl_justify-content-start tl_align-items-center">
                        <form className='auth-form tl_gap-20' data-tp="reg">
                            <Col className="centry tl_gap-15" style={{color: "#ffe500"}}> 
                                <img alt="imag" src={process.env.PUBLIC_URL+"/images/logo_anim.gif"} className="circular imgReg" width={200} height={180} />
                                <Title calibre={1} content="Inscription" style={{letterSpacing: "2px"}} className="tl_mb-20" />
                            </Col>
                            <TextField name="reg_uname" invalidText="au moins 2 caractères" onBlur={whenBlured} isValid={ validName } onInput={isValidName} onFocus={isValidName} label="Votre nom" isRequired={true}/>
                            <TextField name="reg_umail" invalidText="adresse email invalid" onBlur={whenBlured} isValid={ validMail } onInput={mailCheck} type="email" label="Adresse email" isRequired={true}/>
                            <NumField options={numOptions} nsibling={2} name="reg_utel" canUseInput={false} isValid={validNum} invalidText="numéro invalid" onBlur={whenBlured} onFocus={isValidNum} onInput={isValidNum} label="Numéro de téléphone"/>
                            <TextField name="reg_upswd" isValid={validPass} invalidText="au moins 8 caractères" onBlur={whenBlured} onFocus={isValidPassword} onInput={isValidPassword} type="password" label="Mot de passe" pswdIcon="true" isRequired={true}/>
                            <input type="hidden" name="u_reg" value={"u_check"}/>
                            <Row className="tl_align-items-center tl_gap-20">
                                <Text content="J'ai déjà un compte ?" className="tl_display-none tl_color-white tl_mv-10" />  
                                <TextAction className="tl_p-5 tl_border-radius-8 authTuch1" onTouch={(e)=>{authTo("Auth1")}} style={{backdropFilter: "blur(10px)",background: 'rgb(40,40,50,.4)', color: "var(--app-primary-color)"}} content={<Row className="tl_align-items-center tl_gap-10"><span>se connecter</span><i className="bx bx-shield"></i></Row>}/>
                            </Row>
                            <Row className="tl_align-items-center tl_justify-content tl_justify-content-spaceBetween">
                                <TextAction onTouch={changeCond} className="tl_p-5 tl_border-radius-8 authTuch tl_max-w-50" content={
                                    <Row className="tl_align-items-center tl_gap-5"><Icon className="ckecer" icon="bx-circle"/>
                                    <Text content={"Acceptez nos conditions d'utilisation"} className="ellipis"/></Row>
                                }/> <Text content={<Link className="quick-link-1 ellipis" to={"https://depotbetpro.com/conditions-generales/"} target="_blank" rel="noopener noreferrer">Condition générale</Link>} className=""/>
                            </Row>
                            {
                                cond && realValidNum && realValidName && realValidPass && realValidMail ?<SubmitButton type="button" text="s'enregister" id="reg" subType="reg" onClick={submitting} className="tl_mt-5"/>:<></>
                            }
                            <Text content="" className="tl_pv-10" />  
                        </form>
                        <form className='auth-form tl_gap-20 centry' data-tp="log">
                            <Col className="centry tl_gap-15" style={{color: "#ffe500"}}> 
                                <img alt="imag" src={process.env.PUBLIC_URL+"/images/login3.gif"} className="circular" width={250} height={250} />
                                {/* <Title content="Connexion" style={{letterSpacing: "2px"}} className="tl_mb-20" /> */}
                            </Col>
                            <NumField className="log_unum" options={numOptions} nsibling={2} name="log_utel" canUseInput={false} isValid={validLogNum} invalidText="numéro invalid" onBlur={whenBlured} onFocus={isValidLogNum} onInput={isValidLogNum} label="Numéro de téléphone"/>
                            <TextField className="log_upswd" name="log_upswd" type="password" isValid={validLogPass} invalidText="au moins 8 caractères" onBlur={whenBlured} onFocus={isValidLogPassword} onInput={isValidLogPassword} label="Mot de passe" pswdIcon="true" isRequired={true}/>
                            <input type="hidden" name="u_log" value={"u_log"}/>
                            <Row className="tl_align-items-center tl_gap-20">
                                <Text content="Je n'ai pas de compte ?" className="tl_display-none tl_color-white tl_mv-10" />  
                                <TextAction className="tl_p-5 tl_border-radius-8 authTuch2" onTouch={(e)=>{authTo("Auth0")}} style={{backdropFilter: "blur(10px)",background: 'rgb(40,40,50,.4)', color: "var(--app-primary-color)"}} content={<Row className="tl_align-items-center tl_gap-10"><span>s'inscrire</span><i className="bx bxs-shield"></i></Row>}/>
                            </Row>
                            <Row className="tl_align-items-center tl_gap-20">
                                <TextAction className="tl_p-5 tl_border-radius-8 authTuch3" onTouch={forgetPass} style={{backdropFilter: "blur(10px)",background: 'rgb(40,40,50,.4)', color: "var(--app-tertio-color)"}} content={<Row style={{pointerEvents:"none"}} className="tl_align-items-center tl_gap-10"><span style={{pointerEvents:"none"}}>Mot de passe oublié</span><i style={{pointerEvents:"none"}} className="bx bx-shield-quarter"></i></Row>}/>
                            </Row>
                            <SubmitButton type="button" text="se connecter" subType="log" onClick={submitting} className="tl_mt-10 logger"/>
                        </form>
                    </Row>
                </div>
                <div className="ageSlot">
                    { readyC ? <AskAge onReady={terminate} onReturn={onRetur}/> : <></>}
                </div>
            </Row>
            {/* <Col className="">
                <Text content={"by ©thinolord | version 1.0.6"} className="tl_w-100 tl_text-center tl_color-grey" />
            </Col> */}
        </section>
    )
};

export default Auth;